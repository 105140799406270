<script setup>
import { reactive, ref,onMounted } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import { useStore } from '@/stores/serviceAdmin'
import TenantForm from '@/components/serviceAdmin/tenantForm.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const handTransitionSource =() =>{
  const transitionSource = route.name
  store.handTransitionSource(transitionSource)
}
handTransitionSource()
let newTenantData = store.gotNewTenantData
const tableData = newTenantData.tenantOwner
const ruleForm = reactive({
  tenantName: newTenantData.name,
  tenantStatus: newTenantData.enabled,
  tel:newTenantData.tel,
  postNumber: newTenantData.postal_code,
  address: newTenantData.address1,
  url: newTenantData.url
})
//テナントデータ保持のためのメソッド
const setNewTenantData = ()=>{
  newTenantData = {
    "name": ruleForm.tenantName,
    "name_kana": "",
    "tel": ruleForm.tel,
    "postal_code": ruleForm.postNumber,
    "address1": ruleForm.address,
    "address1_kana": "",
    "address2": "",
    "address2_kana": "",
    "url": ruleForm.url,
    "enabled": ruleForm.tenantStatus
  }
  store.handData(newTenantData,"newTenantData")
}

const handleCreateTenantOwner = ()=>{
  setNewTenantData()
  router.push({name:'S_CreateTenantOwner'})
}

const handleEditTenantOwner = async (tenantStaffId) =>{
  await store.handTenantStaffId(tenantStaffId)
  await store.handEditStaffData()  
  setNewTenantData()
  router.push({name:'S_EditTenantOwner'})
}

import { ElNotification } from 'element-plus'
const handleSubmit = async () => {
    setNewTenantData()
    const url = process.env.VUE_APP_BASEURL+"/admin/tenant"
  try {
    await store.postData(url,newTenantData)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  const data = null
  await store.handData(data,"clearTenant")
  ElNotification({
  title: '',
  message: '新規追加したテナントの管理者をテナント編集画面から登録してください。',
  type: 'warning',
  // onClick: handleSubmit.prevent
  })
  await router.push({name:'S_TenantList'})
 }

 const handleBack = () =>{
  const data = null
  store.handData(data,"clearTenant")
  router.push({name:'S_TenantList'})
 }
 const currentView = "create"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テナント新規追加"/>
          <TenantForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            :tenantOwnerList="store.gotNewStaffList"
            @createTenantOwner="handleCreateTenantOwner"
            @editTenantOwner="handleEditTenantOwner"
            @submit="handleSubmit"
            @back="handleBack"
          />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
