import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
export const useSupporterStore = defineStore('supporter', {
  state: () => {
    return {
      userId:undefined,
      userProfileAndInterviewList:undefined,
      userProfileList:undefined,
      userProfileAndInterveiwData:undefined,
      surveyMaster:undefined,
      surveyListFilteredByCategory:undefined,
      userProfileForManagement:undefined,
      surveyList:undefined,
      surveyDataFilteredByMasterId :undefined,
      userProfileData:undefined,
      surveyDataForExport:undefined,
      surveyResultDataByMasterId:undefined,
      surveyMasterByMasterId:undefined,
      interviewListFromScheduledDate:undefined,
      submittedSurveyList:undefined,
    }
  },
  getters: {
    gotUserId: (state)=>state.userId,
    gotUserProfileAndInterviewList: (state)=>state.userProfileAndInterviewList,
    gotUserProfileList: (state)=>state.userProfileList,
    gotUserProfileAndInterveiwData: (state)=>state.userProfileAndInterveiwData,
    gotSurveyMaster: (state)=>state.surveyMaster,
    gotSurveyListFilteredByCategory: (state)=>state.surveyListFilteredByCategory,
    gotUserProfileData: (state)=>state.userProfileData, 
    gotUserProfileForManagement: (state)=>state.userProfileForManagement,
    gotSurveyList: (state)=>state.surveyList,
    gotSurveyDataFilteredByMasterId: (state)=>state.surveyDataFilteredByMasterId,
    gotSurveyDataForExport: (state)=>state.surveyDataForExport,
    gotSurveyResultDataByMasterId: (state)=>state.surveyResultDataByMasterId,
    gotSurveyMasterByMasterId: (state)=>state.surveyMasterByMasterId,
    gotInterviewListFromScheduledDate: (state)=>state.interviewListFromScheduledDate,
    gotSubmittedSurveyList: (state)=>state.submittedSurveyList
  },

  actions: {
    async getList(url,getType){
      const res = await axios.get(url)
      if(getType == "userProfileAndInterviewList"){
        this.userProfileAndInterviewList = res.data.result
      }else if(getType == "userProfileList"){
        this.userProfileList = res.data.result
      }else if(getType == "masterSurvey"){
        this.surveyMaster = res.data.result
      }else if(getType == "surveyList"){
        this.surveyList = res.data.result
      }else if(getType == "userProfileForManagement"){
        this.userProfileForManagement = res.data.result
      }else if(getType == "userProfileAndInterveiwData"){
        this.userProfileAndInterveiwData = res.data.result
      }else if(getType == "surveyDataForExport"){
        this.surveyDataForExport = res.data.result
      }else if(getType == "surveyResultDataByMasterId"){
        this.surveyResultDataByMasterId = res.data.result
      }else if(getType == "surveyMasterByMasterId"){
        this.surveyMasterByMasterId = res.data.result
      }else if(getType == "interviewListFromScheduledDate"){ //相談者一覧の表示用
        this.interviewListFromScheduledDate = res.data.result
      }else if(getType == "submittedSurveyList"){
        this.submittedSurveyList = res.data.result
      }
    },
    handData(data,dataType){
      if (dataType == "surveyListFilteredByCategory"){
        this.surveyListFilteredByCategory = data
      }else if(dataType == "surveyDataFilteredByMasterId"){
        this.surveyDataFilteredByMasterId = data
      }else if(dataType == "userId"){
        this.userId = data
      }else if(dataType == "surveyListFilteredByCategory"){
        this.surveyListFilteredByCategory = data
      }else if(dataType == "userProfileData"){
        this.userProfileData = data
      }
    },

    

    async postData(data , url){
      try{
        await axios.post(url,data)
      }catch(e){
        console.log(e)
      }
    },
    async putData(data , url){
      try{
        const res = await axios.put(url,data)
      }catch(e){
        console.log(e)
      }
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})


