<script setup>
import { useSupporterStore } from '@/stores/supporter'
import {onMounted,Prop,ref,computed,reactive} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from "moment"

const router = useRouter()
const route = useRoute()
const tableRef = ref()
let url =""
let getType =""
const supporterStore = useSupporterStore()
const userProfileAndInterviewList = ref()
let tableData = undefined
let surveyMaster = undefined
let outcomeList = ref()

const getData = async() =>{
  let today = moment().format('YYYYMMDDhhmm')
  try{
    url = process.env.VUE_APP_BASEURL+"/staff/user"
    getType = "userProfileList"
    await supporterStore.getList(url,getType)

    url = process.env.VUE_APP_BASEURL+"/staff/interview"
    getType = "userProfileAndInterviewList"
    await supporterStore.getList(url,getType)

    //今日以降の面談日のインタビューリスト
    url = process.env.VUE_APP_BASEURL+"/staff/interview?from_scheduled_date=" + today
    getType = "interviewListFromScheduledDate"
    await supporterStore.getList(url,getType)

    //実施済みサーベイリスト
    url = process.env.VUE_APP_BASEURL+"/staff/survey?submitted_only=true"
    getType = "submittedSurveyList"
    await supporterStore.getList(url,getType)


    url = process.env.VUE_APP_BASEURL+"/master/survey"
    getType = "masterSurvey"
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  userProfileAndInterviewList.value = supporterStore.gotUserProfileAndInterviewList.sort((a, b) => a.user.id - b.user.id);
  tableData= supporterStore.gotUserProfileAndInterviewList
  surveyMaster = supporterStore.gotSurveyMaster

  supporterStore.$subscribe((mutation, state) => {
    localStorage.setItem('supporter', JSON.stringify(state))
  })
}

const createTableData =()=>{
  outcomeList.value = supporterStore.gotUserProfileList.map(function(data){
    let interviewScheduledDateList = supporterStore.gotInterviewListFromScheduledDate.filter(function(result){
      return data.id == result.user.id && result.enabled
    })
    .map(function(result){
      return result.scheduled_date //面談予定日
    })
    //ユーザごとのサーベイリスト
    let surveyList = supporterStore.gotUserProfileAndInterviewList.filter(function(result){
      return data.id == result.user.id
    }).map(function(result){
      return result.survey
    })

    //サーベイの実施日リスト
    let submittedDateList = []
    surveyList.forEach(function(survey,index){
      survey.filter(function(survey){
        return survey.submitted_date
      }).forEach(function(survey){
         submittedDateList.push(survey.submitted_date)
      })
    })

    let result = {
      "id":data.id,
      "name":data.name,
      "birthday":data.birthday,
      "character":data.character,
      "background":data.background,
      "relationship":data.relationship,
      "scheduledDateList": interviewScheduledDateList.sort(),
      "hasSubmmitedSurvey": submittedDateList.length === 0,
      "enabled":data.enabled
    }
    return result
  })
}

const setData = async()=>{
  await getData()
  await createTableData()
}
setData()

//プロフィールモーダル用のデータをセット
let dataForProfileModal = ref()
let centerDialogVisible = ref(false)
let userProfileData = undefined
const openUserDetailDialog = async(row) =>{
  dataForProfileModal.value = row
  centerDialogVisible.value = true
}

let dataType = ""
let userProfileAndInterveiwData = undefined
const filterProfileData = (row)=>{
  userProfileAndInterveiwData = supporterStore.gotUserProfileAndInterviewList.filter(obj => obj.user.id == row.id)
  supporterStore.handData(userProfileAndInterveiwData,"userProfileAndInterveiwData")

  userProfileData = userProfileAndInterveiwData[0].user
  supporterStore.handData(userProfileData,"userProfileData")
}

let type = ""
let id = ""
let outcomeId = ""
const handUserId = (row) =>{
  id = row.id
  type = "userId"
  supporterStore.handData(id , type)
  outcomeId = supporterStore.gotUserId
}

const surveyListFilteredByCategory = supporterStore.gotSurveyListFilteredByCategory
const filterTestTypeByRelationship = (row) =>{
const surveyListFilteredByCategory = surveyMaster.filter(
  data => {
    if(data.category -1 == row.relationship || data.category == 1){
      return true
    }
  })
  supporterStore.handData(surveyListFilteredByCategory,"surveyListFilteredByCategory")
}

const changeSetTestPage = async(row) =>{

  url = process.env.VUE_APP_BASEURL+"/staff/interview?user_id=" + row.id
  getType = "userProfileAndInterveiwData"
  try{
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  handUserId(row)
  await filterTestTypeByRelationship(row)
  await supporterStore.handData(row,"userProfileData") //面談未実施の人のためにプロフィールデータをセット
  await router.push({name: 'ST_SetTest'});
}

//テスト詳細画面へ
const testResult = async(row) =>{
  await filterProfileData(row)
  handUserId(row)

  url = process.env.VUE_APP_BASEURL+"/staff/survey?submitted_only=true&user_id=" + outcomeId
  getType = "surveyList"
  try{
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name: 'ST_TestResultChart'});
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者一覧"/>
          <el-table ref="tableRef" row-key="no" :data="outcomeList" style="width: 100%">
            <el-table-column type="index" label="No" sortable column-key="no" ></el-table-column>
            <el-table-column prop="name" sortable label="相談者"></el-table-column>
              <el-table-column label="次回面談日">
                <template #default="props">
                  <div v-for="date in props.row.scheduledDateList">{{$filters.displayDateFormat(date)}}</div>
                </template>
              </el-table-column>
            <el-table-column fixed="right" label="プロフィール">
              <template #default="scope">
              <BasicButton @click="openUserDetailDialog(scope.row)" buttonName="相談者詳細" />
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="次回テスト">
              <template #default="scope">
                <BasicButton @click="changeSetTestPage(scope.row)" buttonName="テスト設定" /> 
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="テスト詳細">
              <template #default="scope">
                <BasicButton @click="testResult(scope.row)" buttonName="テスト詳細" :disabled="scope.row.hasSubmmitedSurvey"/>
              </template>
            </el-table-column>
          </el-table> 
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
        <!--モーダル-->
          <el-dialog v-model="centerDialogVisible" title="相談者詳細" width="60%" left>
            <el-row :gutter="20">
              <el-col :span="4"><div class="grid-content" ><a>名前</a></div></el-col>
              <el-col :span="20"><div class="grid-content" ><a v-bind:text="dataForProfileModal.name"></a></div></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="4"><div class="grid-content" ><a>誕生日</a></div></el-col>
              <el-col :span="20"><div class="grid-content" ><a v-bind:text="$filters.displayDateFormat(dataForProfileModal.birthday)"></a></div></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="4"><div class="grid-content" ><a>性格</a></div></el-col>
              <el-col :span="20"><div class="grid-content" ><a v-bind:text="dataForProfileModal.character"></a></div></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="4"><div class="grid-content" ><a>生い立ち</a></div></el-col>
              <el-col :span="20"><div class="grid-content" ><a v-bind:text="dataForProfileModal.background"></a></div></el-col>
            </el-row>
          </el-dialog>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>