<script setup>
import {onMounted,ref,Prop,reactive, toRefs} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {storeToRefs} from 'pinia'
import { useStore } from '@/stores/serviceAdmin'
import TenantForm from '@/components/serviceAdmin/tenantForm.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const gotTenantDataById = store.gotTenantDataById
const tenantId = store.gotEditTenantId

const handTransitionSource =() =>{
  const transitionSource = route.name
  store.handTransitionSource(transitionSource)
}
handTransitionSource()

const ruleForm = reactive({
  tenantName: store.gotTenantDataById.name,
  tenantStatus: store.gotTenantDataById.enabled,
  tel: store.gotTenantDataById.tel,
  postNumber: store.gotTenantDataById.postal_code,
  address: store.gotTenantDataById.address1,
  url: store.gotTenantDataById.url,
})


let tenantData = {}
// tenantDataにデータ詰めるメソッド
const setTenantData = () =>{
  tenantData = 
    { 
      // "id": gotTenantDataById.id,
      "name": ruleForm.tenantName,
      "name_kana": gotTenantDataById.name_kana,
      "tel": ruleForm.tel,
      "postal_code": ruleForm.postNumber,
      "address1": ruleForm.address,
      "address1_kana": gotTenantDataById.address1_kana,
      "address2": gotTenantDataById.address2,
      "address2_kana": gotTenantDataById.address2_kana,
      "url": ruleForm.url,
      "enabled": ruleForm.tenantStatus,
      }
  store.handData(tenantData,"tenantDataById")
}

const handleEditTenantOwner = async(tenantStaffId) => {
  await store.handTenantStaffId(tenantStaffId)
  let url = process.env.VUE_APP_BASEURL+"/admin/manager?id=" + tenantStaffId
  try{
    await store.getList(url,"edit_admin_staff")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name: 'S_EditTenantOwner'})
}

const handleCreateTenantOwner = async()=>{
  await setTenantData()
  await store.handData(tenantData,"tenantData")
  await router.push({name: 'S_CreateTenantOwner'})
}


const handleSubmit  = async() =>{
  await setTenantData()
  const url = process.env.VUE_APP_BASEURL+"/admin/tenant?id=" + tenantId
  try{
    await store.putData(url,tenantData)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name: 'S_TenantList'});
}

const currentView = "edit"
</script>
<template>
  <div class="common-layout">
  <el-container >
    <el-header class="header"><TheHeading/></el-header>
    <el-container class="main-container">
      <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
      <el-main class="main">
        <ScreenName screenName="テナント編集"/>
        <TenantForm
          :parentComponent = "currentView"
          :ruleForm="ruleForm"
          :tenantOwnerList="store.gotManagerListFilterdByIdList"
          @createTenantOwner="handleCreateTenantOwner"
          @editTenantOwner="handleEditTenantOwner"
          @submit="handleSubmit"
         />
      </el-main>
    </el-container>
    </el-container>
  </div>
</template>

