<script setup>
import {storeToRefs} from 'pinia'
import { useTenantStore } from '@/stores/tenantAdmin'
import { ref , onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
const router = useRouter()
const route = useRoute()
const tenantStore = useTenantStore()
const { staffList } = storeToRefs(tenantStore) 
const tableRef = ref()

const handTransitionSource =() =>{
  const transitionSource = route.name
  tenantStore.handTransitionSource(transitionSource)
}

onMounted(()=>{
  getstaffList()
})
const getstaffList = async()=> {
  const getType = "staffList"
  const url = process.env.VUE_APP_BASEURL+"/manager/staff" 
  try {
    await tenantStore.getList(url ,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }

  tenantStore.$subscribe((mutation, state) => {
  localStorage.setItem('tenantAdmin', JSON.stringify(state))
  })
}

const transitCreateNewStaffPage = () =>{
  router.push({name:'T_CreateStaff'})
}
const transitEditStaffPage = async(scope) => {
  const id = scope.row.id
  const type = "staff"
  await tenantStore.handId(id , type)
  const url = process.env.VUE_APP_BASEURL+"/manager/staff?id=" + id
  try{
    await tenantStore.getList(url,"editStaffData")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'T_EditStaff'})
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="スタッフ一覧"/>
            <div class="top-button-area d-flex flex-row-reverse mb-4">
              <BasicButton @click="transitCreateNewStaffPage" buttonName="スタッフ新規追加" />
            </div>
            <el-table ref="tableRef" row-key="no" :data="staffList" style="width: 100%">
              <el-table-column type="index" label="No" sortable column-key="no" />
              <el-table-column prop="name" sortable label="名前"></el-table-column>
              <el-table-column prop="tel" label="電話番号" />
              <el-table-column fixed="right" label="スタッフ編集" width="120">
                <template #default="scope">
                  <BasicButton @click="transitEditStaffPage(scope)" buttonName="編集" />
                </template>
              </el-table-column>
            </el-table>
            <div class="bottom-button-area d-flex">
              <BasicButton @click="$router.back" buttonName="戻る" />
            </div>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>
