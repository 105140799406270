<script setup>
import { useSupporterStore } from '@/stores/supporter'
import {ref} from 'vue'
import { useRouter } from 'vue-router'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-moment';
import { LineChart } from "vue-chart-3";
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from "moment"
Chart.register(...registerables ,annotationPlugin);
const router = useRouter()
const colorMaster = ["#dc143c","#4169e1","#3cb371","#7cfc00","#afeeee","#da70d6","#FF1493","#FF0000","#ffa500","#d2b48c","#a0522d"]
const supporterStore = useSupporterStore()
const chartDataList = ref([])

//実施済みテストを取得
const surveyResultList = supporterStore.gotSurveyList

//masterIdごとにグループ化  //  ここは問題なし
const groupByMasterId = (list, key) =>{
  return list.reduce(function(previousValue, currentValue) {
    (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(currentValue)
    return previousValue
  }, {})
}   // ここまで問題なし


// 半年のスコープかどうかのチェック機構
const isInScope =  (date) =>{ // 半年以内: true 半年以上前: false
  let day = moment()
  let dayBefore6month = day.subtract(6,'months')
  let submittedDate = moment(date)
  return dayBefore6month < submittedDate
}

//グラフ表示用のJSONデータを作る
let allTestResultList = []
const createJsonForDisplayChart = () =>{
  let highestScoreList = []

  //  集計ルーチン書き直し
  let MasterCount = 0;
  for( let _surveyId_ in surveyResultListGroupByMasterId){
    let surveyId
    let surveyName
    let answerArray
    let answerSubmittedDate
    let surveyFullScore
    let totalScore
    let subScore = []
    let submittedDateArray = []
    let submittedDate6MonthArray = []
    let scoreArray = []
    let score6MonthArray = []
    let fullScoreArray = []
    let fullScore6MonthArray = []
    let subScoreObject = {}
    let subScore6MonthObject = {}

    let secondCount = 0;
    surveyResultListGroupByMasterId[_surveyId_].forEach(function(_result_){ //テストごとの結果を処理
      surveyId = _result_.master_id;
      surveyName = _result_.name;
      answerArray = _result_.answer
      answerSubmittedDate = _result_.submitted_date
      surveyFullScore = 0

      //このテストの設問を取得するための準備
      let surveyQuestion = supporterStore.gotSurveyMaster.filter(function(master){
        return master.id == surveyId // 取得済みの全てのテスト詳細から、今回のターゲットの詳細だけにするフィルタ処理
      })

      let questionArray = [];
      surveyQuestion[0].question.group.forEach(function(questionGroup){
        for(let questionLine in questionGroup.questions){
          questionArray.push(questionGroup.questions[questionLine])
        }

      })

      const factorArray =  new Map();
      const displayArray = new Map();
      // 最大点数を集計
      for(let questionLine in questionArray){
        // 因子集計用に設問の因子を配列に
        factorArray.set(questionArray[questionLine].id, questionArray[questionLine].factor);
        // 集計対象とそうでないものを区別するために display-none は集計対象にしない
        displayArray.set(questionArray[questionLine].id, questionArray[questionLine].index);
        surveyFullScore += questionArray[questionLine].maxPoint;
      }

      // ここまででこのテストの集計の下準備完了
      // ここからこのテストのそれぞれの回の集計に入る

      totalScore = 0
      subScore = []
      for(let answerLine in answerArray){
        let displayFlg = displayArray.get(answerArray[answerLine].question_id)
        let lineFactor = factorArray.get(answerArray[answerLine].question_id)
        // display-none は集計対象外
        if( displayFlg !== 'display-none'){
          // 合計得点は無条件に加算
          totalScore += answerArray[answerLine].score

          // 因子ごとの得点を加算
          if(lineFactor.length>0){  // 初登場の因子の場合
            if( subScore[lineFactor] == null){
              subScore[lineFactor] = answerArray[answerLine].score
            }else{ // すでに因子が登場している場合
              subScore[lineFactor] += answerArray[answerLine].score
            }
          }
        }
      }

      submittedDateArray.push(answerSubmittedDate)
      scoreArray.push(totalScore)
      fullScoreArray.push(surveyFullScore)

      for( let lineFactor in subScore){
        if(subScoreObject[lineFactor]== null ){
          subScoreObject[lineFactor] = [subScore[lineFactor]]
        }else{
          subScoreObject[lineFactor].push(subScore[lineFactor])
        }
      }

      if(isInScope(answerSubmittedDate)){  //6ヶ月以内であれば6ヶ月表示用にデータを追加する
        submittedDate6MonthArray.push( answerSubmittedDate );
        score6MonthArray.push( totalScore/surveyFullScore*100 )
        fullScore6MonthArray.push( surveyFullScore )

        for( let lineFactor in subScore){
          if(subScore6MonthObject[lineFactor]== null ){
            let _subScore = subScore[lineFactor] / surveyFullScore * 100
            subScore6MonthObject[lineFactor] = [_subScore]
          }else{
            let _subScore = subScore[lineFactor] / surveyFullScore * 100
            subScore6MonthObject[lineFactor].push(_subScore)
          }
        }
      }
    })
    // 一つの種類のテストの集計が終了したので、そのテストのデータをチャートに持っていく処理
    let chartDataList = []
    let chartDataList6Month = []

    // 総得点のデータ
    let itemCount = 0
    let chartData = {
      "label": "合計",
      "data": scoreArray,
      "borderColor": colorMaster[itemCount],
      "backgroundColor": "#00000000"
    }
    chartDataList.push(chartData)
    chartData = {
      "label": "合計",
      "data": score6MonthArray,
      "borderColor": colorMaster[itemCount],
      "backgroundColor": "#00000000"
    }
    chartDataList6Month.push(chartData)
    itemCount++

    for( let key in subScoreObject ){
      let chartSubData = {
        "label": key,
        "data": subScoreObject[key],
        "borderColor": colorMaster[itemCount],
        "backgroundColor": "#00000000"
      }
      chartDataList.push(chartSubData)
      chartSubData = {
        "label": key,
        "data": subScore6MonthObject[key],
        "borderColor": colorMaster[itemCount],
        "backgroundColor": "#00000000"
      }
      chartDataList6Month.push(chartSubData)
      itemCount++
    }
    //このテストの全結果
    let testResult = {
      "score": chartDataList,
      "name": surveyName,
      "submittedDate": submittedDateArray
    }
    // このテストの半年分の結果
    let testResult6Month ={
      "score": chartDataList6Month,
      "name": surveyName,
      "submittedDate": submittedDate6MonthArray
    }
    allTestResultList.push(
      {
        "allData": testResult,
        "halfYearData": testResult6Month,
        "masterId": surveyId,
        "highestScore": fullScoreArray
      }
    )
  }
}

//★★★★★★★MainMethod★★★★★★★★
let surveyResultListGroupByMasterId = undefined
const setDataForDisplayChart = async() =>{
  surveyResultListGroupByMasterId = await groupByMasterId(surveyResultList,"master_id") //データをグループごとにまとめる
  await createJsonForDisplayChart()
  await setChartDataList()
}
setDataForDisplayChart()

const userProfileData = supporterStore.gotUserProfileData
let name = ref( supporterStore.gotUserProfileData.name)
let relationship = undefined
const setProfileData = () =>{
  if (userProfileData.relationship == 1){
    relationship ="本人"
  }else if(userProfileData.relationship == 2){
    relationship ="相談者家族"
  }
}
setProfileData()



const setChartDataList = () =>{
  for (const [index, testData] of allTestResultList.entries()) {
  let options =  {
      plugins:  {
        colorschemes: {
          scheme: 'brewer.Paired12'
        },
        legend: {
          // display: false,
          align: "start" //凡例テキストを左寄せ
        },
        title: {
          align: "start",
          text: testData.allData.name,
          display: true,
          position: "top",
          font: {
            size: 20
          }
        },
      },
      scales: {
        x: {
          align: "start",
          type: 'time',
          time: {
            displayFormats: {
              month: 'YYYY/MM',
              day: 'YYYY/MM/DD',
              week: 'YYYY/MM/DD'
            
            },
             //unit: 'day',
            unit: 'week',
            stepSize: 1,
          }
        },
        y: {
          display: true,
          suggestedMin: 0,
          //suggestedMax: testData.highestScore[index]
          suggestedMax: 100
        }
      },
  }
  let dialogOptions =  {
      plugins:  {
        colorschemes: {
          scheme: 'brewer.Paired12'
        },
        legend: {
          display: false,
          align: "start" //凡例テキストを左寄せ
        },
        title: {
          align: "start",
          text: testData.allData.name,
          display: true,
          position: "top",
          font: {
            size: 20
          }
        },
      },
      scales: {
        x: {
          align: "start",
          type: 'time',
          time: {
            displayFormats: {
              month: 'YYYY/MM'
            },
            unit: 'week',
            stepSize: 1,
          }
        },
        y: {
          display: true,
          suggestedMin: 0,
          suggestedMax: testData.highestScore[index]
          //suggestedMax: 100
        }
      },
  }

  let chartDataListData =
    {
      data: {
        id: testData.halfYearData.masterId,
        dataInHalfYear: {
          labels: testData.halfYearData.submittedDate,
          datasets: testData.halfYearData.score
        },
        allData: {
          labels:testData.allData.submittedDate,
          datasets: testData.allData.score
        }
      },
      masterId: testData.masterId,
      responsive: false,
      options: options,
      dialogOptions: dialogOptions
    }
  chartDataList.value.push(chartDataListData)
  }
}

  const centerDialogVisible = ref(false)
  // let dialogOptions = undefined
  const dialogChartData = ref()
  const dialogOption = ref()

  const dialogOpen = function(index) {
    //ダイアログのグラフ更新
    dialogChartData.value = chartDataList._rawValue[index].data.allData
    dialogOption.value = chartDataList._rawValue[index].dialogOptions
    centerDialogVisible.value = true
  }

//回答内容ボタン押下時：masterIdと一致するデータのみ遷移先に持っていく
const handleTestResult = async(chart,index)=>{
  let data = undefined
  //masterIdに紐づくテスト結果を取得
  let url =  process.env.VUE_APP_BASEURL+"/staff/survey?submitted_only=true&survey_master_id=" + chart.masterId
  try{
    await supporterStore.getList(url,"surveyResultDataByMasterId")

    //masterIdと一致するマスタデータを取得
    url = process.env.VUE_APP_BASEURL+"/master/survey?id=" + chart.masterId
    await supporterStore.getList(url,"surveyMasterByMasterId")

  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name: 'ST_TestResultList'});
}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者別テスト結果一覧"/>
          <p class="fs-5 fw-bolder text">{{name}} ({{relationship}})</p>
            <div class="container">
              <div class="chartArea" v-for="(chart, index) in chartDataList" :key="index" >
                <LineChart :chartData="chart.data.dataInHalfYear" :options="chart.options" />
                <div class="mx-auto mt-4">
                  <BasicButton @click="dialogOpen(index)" buttonName="グラフ詳細" />
                  <BasicButton v-on:click="handleTestResult(chart,index)" buttonName="回答内容" />
                </div>
              </div>
            </div>
            <el-dialog v-model="centerDialogVisible" title="テスト結果詳細" center>
              <div class="chartWrapper" >
                <div class="chartAreaWrapper" > 
                  <LineChart :chartData="dialogChartData" :options="dialogOption" class="canvas" style="height: 600px;"/>
                </div>
              </div>
            </el-dialog>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
}
.container>.chartArea {
  text-align: center;
  width: 45%;
  margin: 20px auto;
}
 /* dialog */
.chartWrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
}
.chartAreaWrapper {
  margin: 0 auto;
  width: 3000px;
}
.chartWrapper > .canvas {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
