<script setup>
import { useSupporterStore } from '@/stores/supporter'
import {storeToRefs} from 'pinia'
import {onMounted,Prop,ref,computed,reactive} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const tableRef = ref()
let url =""
let getType =""
const supporterStore = useSupporterStore()

const getData = async() =>{
  url = process.env.VUE_APP_BASEURL+"/staff/user"
  getType = "userProfileList"

  try{
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }  
  const userProfileList = supporterStore.gotUserProfileList
  supporterStore.$subscribe((mutation, state) => {
    localStorage.setItem('supporter', JSON.stringify(state))
  })
}
getData()

const outcomeTypeValue = ref('0')
const outcomeTypeOptions = [
  { oValue: '0', oLabel: 'すべて'},
  { oValue: '1', oLabel: '本人' },
  { oValue: '2', oLabel: '家族' }
]
const search = ref('')
let outcomeType = undefined
let filteredTestTypeList = ref()

const filterTableData = computed(() =>
  supporterStore.gotUserProfileList.filter(
    data => {
      if(outcomeTypeValue.value == '0'){
        if(data.name.includes(search.value)){
          return true
        }
      }else{
        if(data.relationship == outcomeTypeValue.value && data.name.includes(search.value)){
          return true
        }
      }
    }))

const formatOutcomeType =(userProfileList, column, cellValue)=> {
  let ret = ''
  if (cellValue == '2') {
    ret = "家族"
  } else if(cellValue == '1'){
    ret = "本人"
  }
  return ret;
}

const handleCreate = async() => {
  router.push({name: 'ST_CreateOutcome'});
}

const handleEdit = async(row) => {
  const id = row.id
  url = process.env.VUE_APP_BASEURL+"/staff/user?id=" + id
  getType = "userProfileForManagement"
  try{
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  const type = "userId"
  await supporterStore.handData(id , type)
  await router.push({name: 'ST_EditOutcome'});
}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者/相談者家族管理"/>
      <div class="search-area">
        <div class="d-flex flex-row mb-3 search-item">
          <label class="col-1 mt-2">相談者区分</label>
          <el-select v-model="outcomeTypeValue" filterable >
            <el-option
              v-for="item in outcomeTypeOptions"
              :key="item.oValue"
              :label="item.oLabel"
              :value="item.oValue"
            />
          </el-select>
        </div>
        <div class="d-flex flex-row mb-3">
          <label class="col-1 mt-2" for="search-by-outcome">相談者</label>
          <el-input v-model="search" size="midium" placeholder="相談者名を入力してください" />
        </div>
      </div>
          <div class="d-flex flex-row-reverse mb-4">
            <BasicButton @click="handleCreate" buttonName="相談者/相談者家族新規追加" />
          </div>
          <el-table  row-key="no" :data="filterTableData" style="width: 100%">
            <el-table-column type="index" label="No" column-key="no" ></el-table-column>
            <el-table-column prop="name" label="名前" sortable ></el-table-column>
            <el-table-column prop="relationship" label="相談者区分" sortable :formatter="formatOutcomeType" />
            <el-table-column prop="tel" label="電話番号" ></el-table-column>
            <el-table-column fixed="right" label="" width="120">
              <template #default="scope">
                <BasicButton @click="handleEdit(scope.row)" buttonName="編集" />
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
        </el-main>
      </el-container >
    </el-container>
  </div>
</template>



