<script setup>
import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/serviceAdmin'
import TenantOwnerForm from '@/components/serviceAdmin/tenantOwnerForm.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const getEditStaffData= async()=>{
  await store.handEditStaffData()
}
getEditStaffData()

const ruleForm = reactive({
  name: store.gotEditStaffData.name,
  furigana: store.gotEditStaffData.name_kana,
  tel: store.gotEditStaffData.tel,
  mailAddress: store.gotEditStaffData.mail,
  confirmMailAddress: store.gotEditStaffData.mail,
  tenantOwnerStatus: store.gotEditStaffData.enabled,
})
const changePage = ()=>{
  if(store.gotTransitionSource == "S_CreateTenant"){
    router.push({name:'S_CreateTenant'})
  }else if (store.gotTransitionSource == "S_EditTenant"){
    router.push({name:'S_EditTenant'})
  }
}
const handleSubmit = async(data)=>{
  let url = undefined
  try{
    if(store.gotTransitionSource == "S_CreateTenant"){
      url = process.env.VUE_APP_BASEURL+"/admin/manager"
      await store.post(url,data)
    }else if (store.gotTransitionSource == "S_EditTenant"){
      url = process.env.VUE_APP_BASEURL+"/admin/manager?id=" + store.gotEditStaffId
      await store.putData(url,data)
    }
    //最新のマネージャーリストを取得
    url = process.env.VUE_APP_BASEURL+"/admin/manager"
    const getType = "admin_manager"
    await store.getList(url,getType)
    await store.filterTenantStaffDataById(store.gotEditTenantId)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await changePage()
}
const currentView = "edit"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テナント管理者編集"/>
          <TenantOwnerForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            @submit="handleSubmit"
          />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>