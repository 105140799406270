<script setup>
import {ref,defineProps,reactive} from 'vue'
const validateTelFormat = (rule, value, callback) => {
  const phoneNum =  value.toString()
  if (phoneNum.match(/^0[789]0\d{8}$/) || phoneNum.match(/^0\d{9}$/)) {
    callback()
  } else {
    callback(new Error('電話番号は固定電話10桁〜携帯電話11桁の数値で入力してください。'))
  }
}
const rules = reactive({
  tel:[
    { required: true, message: '必須項目です。', trigger: 'blur'},
    { validator: validateTelFormat, trigger: 'blur'}
  ]
})
const props = defineProps({
  ruleForm:{
    tel:Number
  }
})
</script>

<script>
export default {
  name: 'PhoneForm'
}
</script>

<template>
  <el-form-item label="電話番号" prop="tel" :rules="rules.tel" >
    <el-input v-model="props.ruleForm.tel" />
  </el-form-item>
</template>