<script setup>
import { reactive, ref, computed } from 'vue'
import { useSupporterStore } from '@/stores/supporter'
import { userMasterStore } from '@/stores/surveyMaster'
import { useRoute, useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'
import moment from "moment"
const supporterStore = useSupporterStore()
const masterStore =  userMasterStore()
const router = useRouter()
const route = useRoute()

let outcomeDataTmp = undefined
let userProfileList = undefined
let url = ""
let getType =""
let testList =ref()
let totalScoreList = undefined
const getData = async() => {
  try {
    //実施者のプルダウンリスト作成のため
    url = process.env.VUE_APP_BASEURL+"/staff/user"
    getType = "userProfileList"
    await supporterStore.getList(url,getType)
    userProfileList = supporterStore.gotUserProfileList

    //テストタイプのプルダウンリスト作成のため
    url = process.env.VUE_APP_BASEURL+"/master/survey"
    getType = "masterSurvey"
    await supporterStore.getList(url,getType)
    testList.value = supporterStore.gotSurveyMaster

    totalScoreList = masterStore.gotTotalScoreList
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
}

let filteredOutcomeList = []
let outcomeOptions = ref()

//実施者リストの初期表示データセット
const setDefaultOutCome = () =>{
  filteredOutcomeList = []//毎回初期化が必要
  let outcome = {outcomeValue: 'all', outcomeLabel: 'すべて'}
  filteredOutcomeList.push(outcome)
  for(const el of userProfileList) {
    outcome = {outcomeValue: el.id, outcomeLabel: el.name}
    filteredOutcomeList.push(outcome)
  }
  outcomeOptions.value = filteredOutcomeList
}
//テストタイプラジオボタンの初期データセット
let filteredTestTypeList = []
let testTypeOptions = ref()
const setDefaultTestTypeOptions = () =>{
  let test = undefined
  for(const el of testList._rawValue) {
    test = {value: el.id, label: el.name}
    filteredTestTypeList.push(test)
  }
  testTypeOptions.value = filteredTestTypeList
}

//画面読み込み時のデータをセット
const setData = async() => {
  await getData()
  await setDefaultOutCome()
  await setDefaultTestTypeOptions() //テストタイプをセット
}
setData()


//実施者リストを更新
const setOutCome = () =>{
  filteredOutcomeList = []//毎回初期化が必要
  let outcome = {outcomeValue: 'all', outcomeLabel: 'すべて'}
  filteredOutcomeList.push(outcome)
  for(const el of outcomeDataTmp) {
    outcome = {outcomeValue: el.id, outcomeLabel: el.name}
    filteredOutcomeList.push(outcome)
  }
  outcomeOptions.value = filteredOutcomeList
}
//テストタイプのラジオボタンを更新
const setTestTypeOptions = (testListTmp) =>{
  filteredTestTypeList = []
  let test = undefined
  for(const el of testListTmp) {
    test = {value: el.id, label: el.name}
    filteredTestTypeList.push(test)
  }
  testTypeOptions.value = filteredTestTypeList
}

const ruleFormRef = ref()
const ruleForm = reactive({
  outcomeType: 0,
  date:"",
  outcomeValue:"all",
  checkedTest:[],
  masterId:1
})

//実施者区分のラジオボタンが選択されたら
const outcomeTypeSelected = ()=>{
  ruleForm.outcomeValue = "all"
  ruleForm.date = ""
  ruleForm.masterId = 1
  testList = supporterStore.gotSurveyMaster
  // サーベイ種別:category (1: 共通, 2: 本人用, 3: 家族用) 
  // 本人との間柄:relationship (1: 本人, 2: 家族)
  let testListTmp = testList.filter(
    data => {
      if(ruleForm.outcomeType == 0){ //実施者区分:all
        return testList
      }else if(ruleForm.outcomeType != 0){
        if(data.category-1 == ruleForm.outcomeType || data.category == 1){
          return true
      }
    }
    }
  )

  if(ruleForm.outcomeType == 0){
    outcomeDataTmp = userProfileList
  }else if(ruleForm.outcomeType != 0){
    outcomeDataTmp = userProfileList.filter(
      data => {
        if(ruleForm.outcomeType == data.relationship){
          return true
        }
    })
  }
  setTestTypeOptions(testListTmp)
  setOutCome()
}


//エクスポートボタンを押下
let outcomeListForExport =[]
const setOutcomeListForExport = ()=>{
  url = process.env.VUE_APP_BASEURL+"/staff/survey?submitted_only=true"
  outcomeListForExport =[]
  if(ruleForm.outcomeValue == "all"){ //実施者のプルダウンリストですべてを選択
    if(ruleForm.outcomeType == 0){
      //実施者区分がすべてだったら、urlの条件なし
    }else{
      url = url + "&relationship=" + ruleForm.outcomeType
    }
  }else{
    //実施者の名前が選択されていたら、user_idをurlに追加
    for (let el in filteredOutcomeList){
      if(filteredOutcomeList[el].outcomeValue == ruleForm.outcomeValue){
        url = url + "&user_id=" + filteredOutcomeList[el].outcomeValue
      }
    }
  }
}

//テスト実施日での絞り込み
let dateRange = undefined
const setDateRangeForExport =()=>{ 
  let startDate = undefined
  let endDate = undefined
  if(ruleForm.date != ""){
    startDate = new Date(ruleForm.date[0])
    endDate = new Date(ruleForm.date[1])
    let sDate = startDate.getFullYear() + ('0' + (startDate.getMonth() + 1)).slice(-2)  + ('0' + startDate.getDate()).slice(-2) + "0000"
    let eDate = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2)  + ('0' + endDate.getDate()).slice(-2) + "2359" 
    url = url + "&from_submitted_date=" + sDate + "&to_submitted_date=" + eDate
  }
}

//サーベイマスタのデータを取得
let surveyMasterGroupData = undefined
let surveyMasterByMasterId = undefined
const getSurveyMasterByMasterId =async()=>{
  url = process.env.VUE_APP_BASEURL+"/master/survey?id=" + ruleForm.masterId
  getType = "surveyMasterByMasterId"
  try{
    await supporterStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  surveyMasterByMasterId = await supporterStore.gotSurveyMasterByMasterId
  surveyMasterGroupData = surveyMasterByMasterId[0].question.group
}

//テスト結果エクスポートのためのデータを取得
let filteringConditions = undefined
let surveyDataForExport = undefined
const getSurveyDataForExport = async()=>{
  try{
    await supporterStore.getList(url,"surveyDataForExport")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }

  surveyDataForExport = supporterStore.gotSurveyDataForExport.filter(function(data){
    return data.master_id == ruleForm.masterId
  }).map(function(result){
    let score = undefined
    let questionId = undefined
    totalScoreList.forEach(function(master){
      if(master.masterId == result.master_id){
        questionId = master.questionId
        return
      }
    })
    result.answer.forEach(function(answer){
      if(answer.question_id == questionId){
        score = answer.score
      }
    })
    result.score = score //APIから返ってきたscoreを上書き
    return result
  })
}

// データ成形の処理
  const iterateObject = (obj)=> {
    let csvList =undefined
    obj.forEach(function(row,index){
      let questionNumberList = [] //設問番号のリスト(ヘッダー)
      let answerList  = []
       row.answer.forEach(function(answer){
        surveyMasterGroupData.forEach(function(group){
          group.questions.forEach(function(question){
            if(question.id == answer.question_id ){
              if(question.index !== "display-none"){
                questionNumberList.push("No." + question.index)
                questionNumberList.push("得点")
                question.choices.forEach(function(choice){
                  if(choice.id == answer.choice_id){
                    answerList.push(choice.label)
                    answerList.push(answer.score)
                  }
                })
              }
            }
          })
        })
      })
      // ヘッダー
      if(index == 0){
        csvList = [
          ["テストタイプ","テスト実施日","総合得点",questionNumberList.join(',')]
        ]
      }

    const displayDateFormat =(value) =>{
      var m = moment(new Date(value));
      var formatDate = m.format('YYYY/MM/DD');
      return  formatDate
    }

      let array = [
        row.name,
        displayDateFormat(row.submitted_date),
        row.score,
        answerList.join(',')
      ]
    csvList.push(array.join(','))
    })
  return csvList.join('\n')
}

import { saveAs } from 'file-saver'
const downloadCSV = ()=> {
  // // ファイル名
  let date = new Date();
  let fileName = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) 
  let csvList = iterateObject(surveyDataForExport)
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom,csvList ], {
    type: 'text/csv',
  })
  saveAs(blob, fileName)
}

const handleExport = async() =>{
  await getSurveyMasterByMasterId()
  await setOutcomeListForExport()
  await setDateRangeForExport()
  await getSurveyDataForExport()
  if(surveyDataForExport.length !== 0){
    await downloadCSV()
  }else{
  ElNotification({
  title: '',
  message: '該当するデータがありません',
  type: 'warning',
  onClick: event.preventDefault()
  })
  }
  
}

</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テスト結果エクスポート"/>
          <p class="fs-5 text">絞り込み条件</p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="150px"
            class="demo-ruleForm"
            :size="formSize"
          >
            <el-form-item label="相談者区分" prop="outcomeType">
              <el-radio-group v-model="ruleForm.outcomeType" v-on:change="outcomeTypeSelected">
                <el-radio :label="0">{{"すべて"}}</el-radio>
                <el-radio :label="1">{{"本人"}}</el-radio>
                <el-radio :label="2">{{"家族"}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="実施者" prop="outcome">
              <el-select v-model="ruleForm.outcomeValue" filterable >
                <el-option
                  v-for="item in outcomeOptions"
                  :key="item.outcomeValue"
                  :label="item.outcomeLabel"
                  :value="item.outcomeValue"
                />
              </el-select> 
            </el-form-item>
            <el-form-item label="テストタイプ" prop="test">
              <el-radio-group v-model="ruleForm.masterId" >
                <div v-for="test in testTypeOptions" style="float: left;" class="ms-1 me-1">
                  <el-radio :label="test.value" :key="test.value">{{test.label}}</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="テスト実施期間" prop="date">
              <el-date-picker
                v-model="ruleForm.date"
                type="daterange"
                range-separator="〜"
                start-placeholder="開始日"
                end-placeholder="終了日"
                format="YYYY/MM/DD"
              />
            </el-form-item>
          </el-form>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back()" buttonName="戻る" />
            <BasicButton @click="handleExport" buttonName="エクスポート" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
