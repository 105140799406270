import { defineStore } from 'pinia'
export const useSideMenu = defineStore('sidemenu', {
  state: () => {
    return {
      menuContent:undefined,
      sidemenuList:undefined
    }
  },
  getters: {
    gotMenuContent: (state)=>state.menuContent,
    gotSidemenuList :(state)=>state.sidemenuList 
  },
  actions: {
    handData(data,type){
      if(type == "sidemenu"){
        this.menuContent = data
      }else if(type == "sidemenuList"){
        this.sidemenuList = data
      }
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
