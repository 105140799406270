<script setup>
import { ref } from 'vue'
import { useAuth } from '@/stores/auth'
import { useSideMenu } from '@/stores/menu'
import {useRouter} from 'vue-router'
const router = useRouter()
const sideMenuStore = useSideMenu()
const authStore = useAuth()
const authList = authStore.gotAuthType
const menuContents = ref(sideMenuStore.gotMenuContent)
</script>

<template>
  <ul class="ms-2 me-2 ps-1">
    <li v-for="item in menuContents" :key="index" class="mb-2 mt-4">
      <router-link :to="item.url" class="sidemenu-item fw-bold" >{{item.title}}</router-link>
    </li>
  </ul>
</template>

<style scoped>
ul{
  list-style: none;
}
.sidemenu-item{
  text-decoration: none;
  color:white;
}
</style>

