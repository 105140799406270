<script setup>
import { useTenantStore } from '@/stores/tenantAdmin'
import {storeToRefs} from 'pinia'
import {onMounted , Prop , ref ,computed} from 'vue'
import { useRoute, useRouter } from 'vue-router'
const tenantStore = useTenantStore()
const { userList } = storeToRefs(tenantStore) // リアクティブさを損なわない為にstoreToRefsを使用
const router = useRouter()
const route = useRoute()
let url = ""
let getType =""

const getData = async () => {
  try {
    url = process.env.VUE_APP_BASEURL+"/manager/staff"
    getType = "staffList"
    await tenantStore.getList(url,getType)

    url = process.env.VUE_APP_BASEURL+"/manager/user"
    getType = "userList"
    await tenantStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  tenantStore.$subscribe((mutation, state) => {
  localStorage.setItem('tenantAdmin', JSON.stringify(state))
  })
}
getData()

//本人との間柄 (1: 本人, 2: 家族)
const userTypeValue = ref(0)
const outcomeTypeOptions = [
  { oValue: 0, oLabel: 'すべて'},
  { oValue: 1, oLabel: '本人' },
  { oValue: 2, oLabel: '家族' }
]

const staffList = tenantStore.gotStaffList
let staffOptions = []
const setStaffOptions = () =>{
  let staff = {sValue: 'all', sLabel: 'すべて'}
  staffOptions.push(staff)
  for(const el of staffList) {
    staff = {sValue: el.name, sLabel: el.name}
    staffOptions.push(staff)
  }
}
setStaffOptions()
const staffValue = ref('all')
const search = ref('')

const filterTableData = computed(() =>
  tenantStore.gotUserList.filter(
    data => {
      if(userTypeValue.value == 0){
        if(staffValue.value == 'all'){
          if(search.value == ""){
            return true
          }else{
            if(data.name.includes(search.value)){
              return true
            }
          }
        }else{
          if(data.name.includes(search.value) && data.staff.name == staffValue.value){
            return true
          }
        }
      }else{
        if(staffValue.value == 'all'){
          if(data.relationship == userTypeValue.value && data.name.includes(search.value)){
            return true
          }
        }else{
          if(data.relationship == userTypeValue.value && (data.name.includes(search.value) && data.staff.name == staffValue.value)){
            return true
          }
        }
      }
    }
    )
)

const formatOutcomeType =(userList, column, cellValue)=> {
  var ret = ''
  if (cellValue == 2) {
    ret = "家族"
  } else if(cellValue == 1){
    ret = "本人"
  }
  return ret;
}

const handleCreate = () => {
  router.push({name: 'T_CreateOutcome'});
}
const handleEdit = async(row) => {
  const id = row.id
  const type = "user"
  await tenantStore.handId(id , type)
  url = process.env.VUE_APP_BASEURL+"/manager/user?id=" + id
  try{
    await tenantStore.getList(url,"editUserData")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name: 'T_EditOutcome'});
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
      <ScreenName screenName="相談者 相談者家族一覧"/>
      <div class="search-area">
        <div class="d-flex flex-row mb-3 search-item">
          <label class="col-1 mt-2">相談者区分</label>
          <el-select v-model="userTypeValue" filterable >
            <el-option
              v-for="item in outcomeTypeOptions"
              :key="item.oValue"
              :label="item.oLabel"
              :value="item.oValue"
            />
          </el-select>
        </div>
        <div class="d-flex flex-row mb-3">
          <label class="col-1 mt-2" for="search-by-outcome">相談者</label>
          <el-input v-model="search" size="midium" placeholder="相談者名を入力してください" />
        </div>
        <div class="d-flex flex-row mb-3">
          <label class="col-1 mt-2">担当スタッフ</label>
          <el-select v-model="staffValue" filterable >
            <el-option
              v-for="item in staffOptions"
              :key="item.sValue"
              :label="item.sLabel"
              :value="item.sValue"
            />
          </el-select> 
        </div>
      </div>
      <div class="d-flex flex-row-reverse mb-4">
        <BasicButton @click="handleCreate" buttonName="相談者 相談者家族新規追加" />
      </div>
          <el-table row-key="no" :data="filterTableData" style="width: 100%">
            <el-table-column type="index" label="No" sortable column-key="no" />
            <el-table-column prop="name" label="名前" sortable ></el-table-column>
            <el-table-column prop="relationship" label="相談者区分" sortable :formatter="formatOutcomeType" />
            <el-table-column prop="staff.name" label="担当スタッフ" sortable />
            <el-table-column fixed="right" label="" width="120">
              <template #default="scope">
                <BasicButton @click="handleEdit(scope.row)" buttonName="編集" />
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


