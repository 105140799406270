<script setup>
import { reactive, ref , computed} from 'vue'
import { FormInstance } from 'element-plus'
import { useServiceStore } from '@/stores/service'
import { useRoute, useRouter } from 'vue-router'
import { userMasterStore } from '@/stores/surveyMaster'
const masterStore =  userMasterStore()
const serviceStore = useServiceStore()
const router = useRouter()
const route = useRoute()
let url = ""
let getType = ""

const testTypeOptions = ref()
const ruleFormRef = ref()
const ruleForm = reactive({
  date:""
})
const testTypeValue = ref("all")
const setTestTypeForSelectBox = async() =>{
  let testTypeListTmp = []
  for (let el in serviceStore.gotTestResultList){
      let testType = serviceStore.gotTestResultList[el].name
      testTypeListTmp.push(testType)
  }

  //テストタイプの重複を排除して、リスト作成
  const testTypeList  = new Set(testTypeListTmp) 
  let list = []
  let type = {testTypeValue: "all", testTypeLabel: "すべて"}
  list.push(type)
  for(const el of testTypeList) {
    type = {testTypeValue: el, testTypeLabel: el}
    list.push(type)
  }
  testTypeOptions.value = list
}
setTestTypeForSelectBox()

url = process.env.VUE_APP_BASEURL+"/user/survey?submitted_only=true"
getType = "testResultList"
const getData = async()=>{
  try{
    serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
}
getData()
// setData=()=>{
//   serviceStore.gotTestResultList.forEach(function(result){
//     masterStore.gotTotalScoreList.map(function(){

//     })
//     // result.master_idとmasterStore.gotTotalScoreListのmasterIdが一致していたら、
//     // resultの質問リストを回して、result.questionIdと一致する得点を取り出す
//     // serviceStore.gotTestResultListに新しいキーを追加して、画面側のテーブルで表示する
    
//   })
// }
// setData()
const filterTableData = computed(() =>
  serviceStore.gotTestResultList.filter(
    data => {
      if(testTypeValue.value == 'all'){
        if(ruleForm.date == ""){
          return true
        }else{
          if(new Date(ruleForm.date[0]) <= new Date(data.submitted_date) && new Date(data.submitted_date) <= new Date(ruleForm.date[1])){
            return true
          }
        }
      }else{
        if(testTypeValue.value == data.name){
          if(ruleForm.date == ""){
            return true
          }else{
            if(new Date(ruleForm.date[0]) <= new Date(data.submitted_date) && new Date(data.submitted_date) <= new Date(ruleForm.date[1])){
              return true
            }
          }
        }
      }
    }
  ).map(function(result){
    let score = undefined
    let questionId = undefined
    masterStore.gotTotalScoreList.forEach(function(master){
      if(master.masterId == result.master_id){
        questionId = master.questionId
        return
      }
    })
    result.answer.forEach(function(answer){
      if(answer.question_id == questionId){
        score = answer.score
      }
    })
    result.score = score //APIから返ってきたscoreを上書き
    return result
  })
)

let masterSurveyDataByMasterId = undefined
const getMasterDataByMasterId = async(masterId) =>{
  url = process.env.VUE_APP_BASEURL+"/master/survey?id=" + masterId
  getType = "masterSurveyDataByMasterId"
  try{
    await serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }
  masterSurveyDataByMasterId = serviceStore.gotMasterSurveyDataByMasterId
}
//モーダル表示するマスタデータ
let totalScore = ref()
let totalScoreId = undefined
let surveyMasterDataForModal = ref() 
const filterDataForModal = (row) =>{
  let testData = masterSurveyDataByMasterId[0].question.group
  const groups = testData.map(function(group){
    let questions = group.questions.filter(
      (question) => {
        if(question.label == "total_score"){
          totalScoreId = question.id
        }
        return question.index !== "display-none"
        }
    )
    group.questions = questions
    return group
  })
  row.answer.forEach(function(answer){
    if(answer.question_id == totalScoreId){
      totalScore.value = answer.score
    }
  })
  testData = groups
  surveyMasterDataForModal.value = testData

  testResultForModal.value = row
  centerDialogVisible.value = true
}




const testResultForModal = ref()
const centerDialogVisible = ref(false)
const handleDetail = async(row) =>{
  //①モーダルで表示したいサーベイをサーベイマスタをから取得する
  await getMasterDataByMasterId(row.master_id)
  await filterDataForModal(row)

}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
          <ScreenName screenName="過去試験結果一覧"/>
          <p class="fs-5 text">絞り込み条件</p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="150px"
            class="demo-ruleForm"
            :size="formSize"
          >
            <el-form-item label="テストタイプ" prop="">
              <el-select v-model="testTypeValue" filterable >
                <el-option
                  v-for="item in testTypeOptions"
                  :key="item.testTypeValue"
                  :label="item.testTypeLabel"
                  :value="item.testTypeValue"
                />
              </el-select> 
            </el-form-item>
            <el-form-item label="テスト実施期間" prop="">
              <el-date-picker
                v-model="ruleForm.date"
                type="daterange"
                range-separator="〜"
                start-placeholder="開始日"
                end-placeholder="終了日"
                format="YYYY/MM/DD"
              />
            </el-form-item>
          </el-form>
          <el-table row-key="no" :data="filterTableData" style="width: 100%">
            <el-table-column  label="実施日" sortable >
              <template #default="props">
                <p >{{$filters.displayDateFormat(props.row.submitted_date) }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="テストタイプ" sortable></el-table-column>
            <el-table-column label="得点" sortable>
              <template #default="scope">
                {{scope.row.score}}
              </template>
            </el-table-column>
            <el-table-column  label="結果詳細">
              <template #default="scope">
                <BasicButton @click="handleDetail(scope.row)" buttonName="回答を見る" />
              </template>
            </el-table-column>
          </el-table>
          <!-- modal -->
          <el-dialog v-model="centerDialogVisible" title="テスト結果詳細" width="90%" center class="modal">
            <div class=" d-flex mx-auto" style="width: 80%;">
              <table class="table me-auto mb-4" style="width: 45%;">
                <tbody>
                  <tr><td class="fw-bold">テストタイプ</td><td>{{testResultForModal.name}}</td></tr>
                  <tr><td class="fw-bold">実施日</td><td>{{$filters.displayDateFormat(testResultForModal.submitted_date)}}</td><td></td></tr>
                  <tr><td class="fw-bold">得点</td><td>{{totalScore}}点</td><td></td></tr>
                </tbody>
              </table>
            </div>
            <div class="overflow-y-wrap">
              <div class="overflow-y2">
                <div v-for="surveyData in surveyMasterDataForModal" class="mt-2 mb-4">
                  <div class="mb-2">{{surveyData.description}}</div>
                  <el-table row-key="no" :data="surveyData.questions" style="width: 100%">
                      <el-table-column prop="index" label="No" width="100"></el-table-column>
                      <el-table-column prop="label" label="設問"></el-table-column>
                      <el-table-column label="回答">
                        <template #default="props">
                          <div v-for="choice in props.row.choices">
                            <div v-if="choice.id == testResultForModal.answer[props.row.id].choice_id">
                              {{choice.label}}
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </el-dialog>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back()" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style scoped>
.overflow-y-wrap{
  height: 400px;
}
.overflow-y2{
  overflow-y: scroll;
  height: 100%;
}
</style>