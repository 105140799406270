<script setup>
import { reactive, ref } from 'vue'
import { useSupporterStore } from '@/stores/supporter'
import { useRoute, useRouter } from 'vue-router'
import OutcomeForm from '@/components/supporter/outcomeForm.vue'
const supporterStore = useSupporterStore()
const router = useRouter()
const route = useRoute()
const ruleForm = reactive({
  name: "",
  furigana: "",
  tel: "",
  mailAddress: "",
  confirmMailAddress: "",
  outcomeType: 1,
  statusFlg: true,
  password:""
})
let outcomeData = undefined
const setOutcomeData = ()=>{
  outcomeData = {
      "name": ruleForm.name,
      "name_kana": ruleForm.furigana,
      "mail": ruleForm.mailAddress,
      "tel": ruleForm.tel,
      "enabled": ruleForm.statusFlg,
      "password": ruleForm.password,
      "relationship": ruleForm.outcomeType,
    }
}
const submitNewUserProfile = async()=>{
  await setOutcomeData()
  try{
    const url = process.env.VUE_APP_BASEURL+"/staff/user"
    await supporterStore.postData(outcomeData , url)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'ST_OutcomeManegement'})
}
const currentView = "create"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者/相談者家族新規追加"/>
          <OutcomeForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            @submit="submitNewUserProfile()"
          />
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>