<script setup>
import { reactive, ref } from 'vue'
import { useServiceStore } from '@/stores/service'
import { useRouter } from 'vue-router'
import ProfileForm from '@/components/service/profileForm.vue'
const serviceStore = useServiceStore()
const router = useRouter()
let url = undefined
let getType = undefined

const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  name: "",
  furigana: "",
  tel: "",
  mailAddress: "",
  confirmMailAddress: "",
  birthday: "",
  personality: "",
  upbringing: ""
})

import axios from 'axios'
const setData = async() =>{
  url = process.env.VUE_APP_BASEURL+"/user/profile"
  getType = "userProfile"
  try{
    await serviceStore.getList(url , getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }

  ruleForm.name = serviceStore.gotUserProfile.name
  ruleForm.furigana = serviceStore.gotUserProfile.name_kana
  ruleForm.tel = serviceStore.gotUserProfile.tel
  ruleForm.mailAddress = serviceStore.gotUserProfile.mail
  ruleForm.confirmMailAddress = serviceStore.gotUserProfile.mail
  ruleForm.birthday = serviceStore.gotUserProfile.birthday
  ruleForm.personality = serviceStore.gotUserProfile.character
  ruleForm.upbringing = serviceStore.gotUserProfile.background

  serviceStore.$subscribe((mutation, state) => {
    localStorage.setItem('service', JSON.stringify(state))
  })
}
setData()

let data = undefined
const setSubmitData = ()=>{
  const birthday = new Date(ruleForm.birthday)
  data = {
    "name": ruleForm.name,
    "name_kana": ruleForm.furigana,
    "tel": ruleForm.tel,
    "mail": ruleForm.mailAddress,
    "birthday": birthday.getFullYear() + "-" + (birthday.getMonth() + 1) + "-" + birthday.getDate(),
    "character": ruleForm.personality,
    "background": ruleForm.upbringing
  }
}

const submitUserProfile = async() =>{
  url = process.env.VUE_APP_BASEURL+"/user/profile"
  try{
    await serviceStore.putData(url,data)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }
}

const handleSubmit = async()=>{
  await setSubmitData()
  await submitUserProfile()
  await router.push({name:'Index'})
}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="プロフィール設定"/>
            <ProfileForm
              :ruleForm="ruleForm"
              @submit="handleSubmit" />
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>

