<script setup>
import {ref,defineProps,reactive} from 'vue'
const rules = reactive({
  statusFlg:[
    { required: true, message: '必須項目です。', trigger: 'blur'}
  ]
})
const props = defineProps({
  ruleForm:{
    statusFlg:Boolean,
    tenantOwnerStatus:Boolean,
    tenantStatus:Boolean
  },
  statusType:String
})

</script>
<script>
export default {
  name: 'StatusFlg'
}
</script>

<template>
  <template v-if="props.statusType == 'statusFlg'" >
    <el-form-item prop="statusFlg" :rules="rules.statusFlg">
      <el-radio-group v-model="props.ruleForm.statusFlg">
        <el-radio :label="true">{{"有効"}}</el-radio>
        <el-radio :label="false">{{"無効"}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </template>
  <template v-else-if="props.statusType == 'tenantOwnerStatus'" >
    <el-form-item prop="tenantOwnerStatus" :rules="rules.statusFlg">
      <el-radio-group v-model="props.ruleForm.tenantOwnerStatus">
        <el-radio :label="true" >{{"有効"}}</el-radio>
        <el-radio :label="false">{{"無効"}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </template>
  <template v-else-if="props.statusType == 'tenantStatus'" >
    <el-form-item prop="tenantStatus" :rules="rules.statusFlg">
      <el-radio-group v-model="props.ruleForm.tenantStatus">
        <el-radio :label="true" >{{"有効"}}</el-radio>
        <el-radio :label="false">{{"無効"}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </template>
</template>