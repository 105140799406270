 <script setup>
import {ref,defineProps,defineEmits,defineExpose} from 'vue'
import { useServiceStore } from '@/stores/service'
const serviceStore = useServiceStore()
const props = defineProps({
  selectedTest:Object,
  surveyId:Number,
  masterId:Number
})
const emit = defineEmits(['submit'])
const name = ref()
const description = ref()
const optionsDescription = ref()
const optionsList = ref([])
const testList = ref()
const checkedValues = ref({})

let testData = undefined
let testName = undefined
let dataForComponentForMolding = undefined
let masterSurveyData = ref()
let masterQuestionData = undefined
const setData = (dataForComponent) =>{
  //テスト計算時にデータをつきわせるために取得する
  masterSurveyData.value = serviceStore.gotMasterSurveyDataByMasterId
  masterQuestionData = serviceStore.gotMasterSurveyDataByMasterId[0].question.group

  dataForComponentForMolding = JSON.parse( JSON.stringify(dataForComponent ) )
  let originalTestData = serviceStore.gotFilteredSurveyDataByMasterId[0]
  testData = dataForComponentForMolding.question.group
  
  const groups = testData.map(function(group){
    let questions = group.questions.filter(
      (question) => {
        return question.index !== "display-none"
        }
    )
    group.questions = questions
    return group
  })
  testData = groups
  testName = dataForComponent.name
  testList.value = testData
}

let display = ref(false)
const childMethod = (dataForComponent) =>{
  display.value = true
  setData(dataForComponent)
}
defineExpose({
    childMethod
});

import { ElNotification } from 'element-plus'
let isAnswerCompleted = false
const checkAnswer =()=>{
  let answerListLength = Object.keys(checkedValues._rawValue).length
  let testListLength = 0
  for(let questionGroup in testData){
    let questionGroupLength = 0
    console.log(testData[questionGroup].questions) //問題の配列[]
    questionGroupLength = Object.keys(testData[questionGroup].questions).length
    testListLength += questionGroupLength
  }

  if (answerListLength !== testListLength){
    ElNotification({
      title: '',
      message: '未回答の問題があります。回答を完了させてください。',
      type: 'error',
      onClick: submit.prevent
    })
    return
  }else{
    isAnswerCompleted = true
  }
}

let score = undefined
let answerList = undefined
const submit = async()=>{
  await checkAnswer()
  if(isAnswerCompleted){
    answerList = []
    let additionalPoint = 0
    let hasFactor = false
    //テストグループを回す
    for(let questionGroup in testData){
      let data = undefined
        for (let questionIndex in testData[questionGroup].questions){
          let point = undefined
          for(let choice in testData[questionGroup].questions[questionIndex].choices){
            let questionNumber = testData[questionGroup].questions[questionIndex].index
              if(testData[questionGroup].questions[questionIndex].choices[choice].label == checkedValues.value[questionNumber]){
                data = {
                  "question_id":testData[questionGroup].questions[questionIndex].id,
                  "choice_id":testData[questionGroup].questions[questionIndex].choices[choice].id,
                  "score":testData[questionGroup].questions[questionIndex].choices[choice].point,
                }
                if(testData[questionGroup].questions[questionIndex].factor){
                  hasFactor = true
                  data.factor = testData[questionGroup].questions[questionIndex].factor
                }
                answerList.push(data)
                point = testData[questionGroup].questions[questionIndex].choices[choice].point
                // additionalPoint += testData[questionGroup].questions[questionIndex].choices[choice].point
              }
          }
          additionalPoint += point
        }
    }

    //リストを因子ごとにグループ化
    let listTmp = []
    let surveyResultGroupByFactor = undefined
      const groupByFactor = (list, key) =>{
        return list.reduce(function(previousValue, currentValue) {
          (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(currentValue)
          return previousValue
        }, {})
      }

    //因子が設定されている場合のみグループ化 ⇨ factorごとの点数リストを作成
    let factorScoreList = []
    if(hasFactor){
      surveyResultGroupByFactor = groupByFactor(answerList,'factor')

      //因子が設定されている場合のみ、因子ごとの合計を計算して、リストに追加
      for(let factor in surveyResultGroupByFactor){
        let factorScore = 0
        let factorScoreData = undefined
        for(let question in surveyResultGroupByFactor[factor]){
          factorScore += (surveyResultGroupByFactor[factor])[question].score
        }
        factorScoreData = {"factorName":factor,  "score":factorScore}
        factorScoreList.push(factorScoreData)
      }
    }

    let groups = masterQuestionData
    for(let groupIndex in groups){
      let questions = groups[groupIndex].questions
      for(let questionIndex in questions){
        let question = questions[questionIndex]
        let scoreData = undefined
        if(question.index == "display-none"){
          if(question.label == "total_score"){ //合計点をAnswerListにつめる
            scoreData = {"question_id":question.id, "choice_id":question.id , "score":additionalPoint}
          }else if(question.label == "factor"){ //因子ごとの点数をAnswerListにつめる
            console.log("factor")
            for(let factorScore in factorScoreList){ //factorScoreList テスト計算時に作成しているリスト
              if(question.factor == factorScoreList[factorScore].factorName){ //question.factorマスタデータから取得しているリスト
                scoreData = {"question_id":question.id, "choice_id":question.id , "score":factorScoreList[factorScore].score}
             }
            }
          }
          answerList.push(scoreData)
        }
      }
    }
    //factorがあるテストだった場合
      for(let el in answerList){
        if(answerList[el].factor){
          delete answerList[el].factor
        }
      }

    const testAnswer = {
      // "id": props.surveyId,
      "answer": answerList
    }
    await emit('submit',testAnswer)
  }
}


</script>
<template>
  <el-form >
    <div v-show="display">
      <div class="mb-4 mt-5">
        <p class="fw-bold fs-5">{{name}}</p>
      </div>
      <div v-for="surveyData in testList" class="mt-2 mb-4">
        <div class="mb-2">{{surveyData.description}}</div>
        <el-table row-key="no" :data="surveyData.questions" style="width: 100%">
            <el-table-column prop="index" label="No" width="100"></el-table-column>
            <el-table-column prop="label" label="設問"></el-table-column>
            <el-table-column label="回答">
              <template #default="props">
                <el-radio-group v-model="checkedValues[props.row.index]" >
                  <div v-for="choice in props.row.choices" style="float: left;" class="ms-1 me-1">
                    <el-radio  :key="choice.id" :label="choice.label" :value="choice.point" ></el-radio>
                  </div>
                </el-radio-group>
              </template>
            </el-table-column>
        </el-table>
        
      </div>
    </div>
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton @click="$router.back()" buttonName="戻る" />
        <BasicButton  v-show="display" @click="submit" buttonName="回答完了" />
      </el-form-item>
    </div>
  </el-form>
</template>