<script setup>
import {useRouter} from 'vue-router'
const router = useRouter()
const changeLogin = async()=>{
  await localStorage.clear()
  await $cookies.remove('authToken')
  await $cookies.remove('expiresIn')
  await router.push({name: 'Login'})
}
</script>
<template>
  <body class="" id="app">
    <main class="form-signin">
      <p class="fw-bold fs-4 text-secondary text-center">エラー</p>
      <p class="text-secondary text-area text-center">正しいID、パスワードでログインしてください。</p>
      <div class="bottom-button-area d-flex text-center">
        <BasicButton @click="changeLogin" buttonName="ログイン画面へ" />
      </div>
    </main>
  </body>
</template>
<style scoped>
body {
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form-signin {
  width: 100%;
  max-width:750px;
  height:150;
  padding: 15px;
  margin: 0 auto;
  background-color: #E8F5FB;
  border-radius: 4px;
}
/* .text-area{
  margin-left: 60px;
} */
</style>