<script setup>
import { reactive, ref } from 'vue'
import {useRouter} from 'vue-router'
import { useStore } from '@/stores/serviceAdmin'
import TenantOwnerForm from '@/components/serviceAdmin/tenantOwnerForm.vue'
const router = useRouter()
const store = useStore()
const ruleForm = reactive({
  name: store.gotNewManager.name,
  furigana: store.gotNewManager.name_kana,
  tel: store.gotNewManager.tel,
  mailAddress: store.gotNewManager.mail,
  confirmMailAddress: store.gotNewManager.mail,
  tenantOwnerStatus: store.gotNewManager.enabled,
  tenantId:store.gotNewManager.tenantId
})

const changePage = ()=>{
  if(store.gotTransitionSource == "S_CreateTenant"){
    router.push({name:'S_CreateTenant'})
  }else if (store.gotTransitionSource == "S_EditTenant"){
    router.push({name:'S_EditTenant'})
  }
}
const handleSubmit = async (data)=>{
  try{
      let url = process.env.VUE_APP_BASEURL+"/admin/manager"
      await store.postData(url,data)

      //最新のマネージャーリストを取得
      url = process.env.VUE_APP_BASEURL+"/admin/manager"
      const getType = "admin_manager"
      await store.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await store.filterTenantStaffDataById(store.gotEditTenantId)
  await changePage()
}
const currentView = "create"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テナント管理者新規追加"/>
          <TenantOwnerForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            @submit="handleSubmit"
          />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>