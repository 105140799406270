<script setup>
import { reactive, ref } from 'vue'
import { FormInstance } from 'element-plus'
import { useTenantStore } from '@/stores/tenantAdmin'
import { useRoute, useRouter } from 'vue-router'
import StaffForm from '@/components/tenantAdmin/staffForm.vue'
const tenantStore = useTenantStore()
const router = useRouter()
const route = useRoute()
const ruleForm = reactive({
  name: tenantStore.gotEditStaffData.name,
  furigana: tenantStore.gotEditStaffData.name_kana,
  tel: tenantStore.gotEditStaffData.tel,
  mailAddress: tenantStore.gotEditStaffData.mail,
  confirmMailAddress: tenantStore.gotEditStaffData.mail,
  // tenantOwnerStatus: tenantStore.gotEditStaffData.tenantAdminFlg,
  statusFlg: tenantStore.gotEditStaffData.enabled
})


let staffDataForSubmit = undefined
const setStaffDataForSubmit = ()=>{
  staffDataForSubmit = {
        "id": tenantStore.gotEditStaffData.id,
        // "tenantAdminFlg": ruleForm.tenantOwnerStatus,
        "name": ruleForm.name,
        "name_kana": ruleForm.furigana,
        "mail": ruleForm.mailAddress,
        "tel": ruleForm.tel,
        "enabled": ruleForm.statusFlg
        }
}
const handleSubmit = async()=>{
  const url = process.env.VUE_APP_BASEURL+"/manager/staff?id=" + tenantStore.gotStaffId
  setStaffDataForSubmit()
  try{
    await tenantStore.putData(staffDataForSubmit , url)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'T_StaffList'})
}
const currentView = "edit"
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="スタッフ編集"/>
          <StaffForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            ref="childRef"
            @submit="handleSubmit"
          />
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>