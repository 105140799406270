<script setup>
import {ref,computed,defineProps,reactive} from 'vue'
import { useStore } from '@/stores/serviceAdmin'
import {  useRouter } from 'vue-router'
import NameForm from '@/components/modules/nameForm.vue'
import FuriganaForm from '@/components/modules/furiganaForm.vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import StatusFlg from '@/components/modules/statusFlg.vue'
import MailForm from '@/components/modules/mailForm.vue'
import PasswordForm from '@/components/modules/passwordForm.vue'

const store = useStore()
const router = useRouter()
const formSize = ref('default')
const ruleFormRef = ref()

const props = defineProps({
  ruleForm:{
    name:String,
    furigana:String,
    tel:String,
    mailAddress:String,
    confirmMailAddress:String,
    password:String,
    tenantOwnerStatus:Boolean
  },
  parentComponent:String
})

const emit = defineEmits(['submit']) 
const submitForm = (formEl) => {
  let id = undefined
  let data = undefined
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      data = {
        // "id": store.gotEditStaffData.id,
        "name": props.ruleForm.name,
        "name_kana": props.ruleForm.furigana,
        "mail": props.ruleForm.mailAddress,
        "tel": props.ruleForm.tel,
        // "password": props.ruleForm.password,
        "enabled": props.ruleForm.tenantOwnerStatus,
        "tenant_id": store.gotEditTenantId
      }
      if(store.gotTransitionSource == "S_EditTenant"){ 
        data.password = props.ruleForm.password
      }
      emit('submit',data)
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}
</script>
<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="150px"
    :size="formSize"
    >
    <NameForm v-model="props.ruleForm.name" :ruleForm ="ruleForm" :model="ruleForm" />
    <FuriganaForm v-model="props.ruleForm.furigana" :ruleForm ="ruleForm" :model="ruleForm" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <MailForm 
      v-model:mailAddress="props.ruleForm.mailAddress"
      v-model:confirmMailAddress="props.ruleForm.confirmMailAddress" 
      :ruleForm ="ruleForm" :model="ruleForm" />
    <PasswordForm v-if="parentComponent == `create`" v-model="props.ruleForm.password" :ruleForm ="ruleForm" :model="ruleForm" />
    <StatusFlg v-model="props.ruleForm.tenantOwnerStatus" :ruleForm ="ruleForm" :model="ruleForm" statusType="tenantOwnerStatus" label="テナント管理者状態" />
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton @click="$router.back" buttonName="戻る" />
        <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
      </el-form-item>
    </div> 
  </el-form>
</template>
