import axios from 'axios'
import { defineStore } from 'pinia'
export const useResetPasswordStore = defineStore('resetPassword', {
  state: () => {
    return {
    }
  },
  getters: {
  },
  actions: {
    async postData(url,data){
      const res = await axios.post(url,data)
    },
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
