<template>
  <p class="fw-bold fs-3 scren-name mb-4">{{screenName}}</p>
</template>
<script>
export default {
  name: 'ScreenName',
  props: {
    screenName: {
      type: String,
      default: "",
    },
  }
}
</script>