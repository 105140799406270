<script setup>
import {defineProps,reactive} from 'vue'
const rules = reactive({
  outcomeType:[
    { required: true, message: '必須項目です。', trigger: 'blur'}
  ]
})
const props = defineProps({
  ruleForm:{
    outcomeType:String
  }
})
</script>

<script>
export default {
  name: 'OutcomeTypeForm'
}
</script>

<template>
  <el-form-item label="相談者区分" prop="outcomeType" :rules="rules.outcomeType">
    <el-radio-group v-model="props.ruleForm.outcomeType">
      <el-radio :label="1">{{"本人"}}</el-radio>
      <el-radio :label="2">{{"家族"}}</el-radio>
    </el-radio-group>
  </el-form-item>
</template>