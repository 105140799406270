// import axios from '@/plugins/axios'
import { defineStore } from 'pinia'

export const userMasterStore = defineStore('surveyMaster', {
  state: () => {
    return {
      totalScoreList:undefined
    }
  },
  getters: {
    gotTotalScoreList:(state)=>state.totalScoreList,
  },
  actions: {
    handData(data){
      this.totalScoreList = data
    }

  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
