import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/global.css'
import "bootstrap/dist/css/bootstrap.min.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from "pinia"
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
import BasicButton from "@/components/modules/button.vue"
import ScreenName from "@/components/modules/screenName.vue"
import TheHeading from '@/components/modules/TheHeading.vue'
import TheSideBar from '@/components/modules/TheSideBar.vue'
import moment from "moment"
import VueCookies from 'vue-cookies'

// ローカルストレージの保持の場合はこれを使う
const plugin = (app) => {
  const pinia = createPinia() // Piniaを有効化
  const installPersistedStatePlugin = createPersistedStatePlugin()
  pinia.use((context) => installPersistedStatePlugin(context))
  app.use(pinia)
}

// import piniaPersist from 'pinia-plugin-persist'
// const pinia = createPinia()
// pinia.use(piniaPersist)

//ISO8061形式の日付データを[ YYYY/MM/DD ]形式に変更して画面側で表示する
const filter = (app)=>{
  app.config.globalProperties.$filters = {
    displayDateFormat(value) {
      // var m = moment(new Date(value));
      // var formatDate = m.format('YYYY/MM/DD');
      var formatDate = moment.utc(value).local().format('YYYY/MM/DD')
      return  formatDate
    }
  }
}

// new Vue({
//   router,
//   render: (h) => h(App),
// }).$mount('#app')

const app = createApp(App)
app.use(VueCookies)
app.use(ElementPlus)
app.use(router)
app.use(plugin)
app.use(moment)
app.use(filter)
app.component('BasicButton',BasicButton)
app.component('TheHeading',TheHeading)
app.component('TheSideBar',TheSideBar)
app.component('ScreenName',ScreenName)
app.mount('#app')


