<script setup>
import {ref,defineProps,reactive} from 'vue'
const rules = reactive({
  name:[{ required: true, message: '必須項目です。', trigger: 'blur'},
        { min: 3, max: 20, message: '3字以上20字以下で入力してください。', trigger: 'blur' }]
})
const props = defineProps({
  ruleForm:{
    name:String
  }
})
</script>

<script>
export default {
  name: 'NameForm'
}
</script>

<template>
  <el-form-item label="名前" prop="name" :rules="rules.name" >
    <el-input v-model="props.ruleForm.name" />
  </el-form-item>
</template>