import { createRouter, createWebHistory, useRouter } from 'vue-router'
import multiguard from 'vue-router-multiguard';

//error
import InternalServerError from '@/pages/errors/500'

//password
import PasswordMail from '@/pages/password/PasswordMail.vue'
import TokenError from '@/pages/password/TokenError.vue'


// common
import Login from '@/pages/common/Login.vue'
import InputMailAdress from '@/pages/common/InputMailAdress.vue'
import ChangePassword from '@/pages/common/ChangePassword.vue'

// serciveAdmin
import S_Index from '@/pages/serviceAdmin/Index.vue'
import S_TenantList from '@/pages/serviceAdmin/TenantList.vue'
import S_EditTenant from '@/pages/serviceAdmin/EditTenant.vue'
import S_CreateTenant from '@/pages/serviceAdmin/CreateTenant.vue'
import S_EditTenantOwner from '@/pages/serviceAdmin/EditTenantOwner.vue'
import S_CreateTenantOwner from '@/pages/serviceAdmin/CreateTenantOwner.vue'

// tenantAdmin
import T_Index from '@/pages/tenantAdmin/Index.vue'
import T_CreateOutcome from '@/pages/tenantAdmin/CreateOutcome.vue'
import T_EditOutcome from '@/pages/tenantAdmin/EditOutcome.vue'
import T_CreateStaff from '@/pages/tenantAdmin/CreateStaff.vue'
import T_EditStaff from '@/pages/tenantAdmin/EditStaff.vue'
import T_OutcomeList from '@/pages/tenantAdmin/OutcomeList.vue'
import T_StaffList from '@/pages/tenantAdmin/StaffList.vue'
import T_VideoList from '@/pages/tenantAdmin/VideoList.vue'
import T_VideoManagement from '@/pages/tenantAdmin/VideoManagement.vue'

// supporter
import ST_Index from '@/pages/supporter/Index.vue'
import ST_CreateOutcome from '@/pages/supporter/CreateOutcome.vue'
import ST_EditOutcome from '@/pages/supporter/EditOutcome.vue'
import ST_OutcomeList from '@/pages/supporter/OutcomeList.vue'
import ST_OutcomeManegement from '@/pages/supporter/OutcomeManegement.vue'
import ST_SetTest from '@/pages/supporter/SetTest.vue'
import ST_TestResultChart from '@/pages/supporter/TestResultChart.vue'
import ST_TestResultExport from '@/pages/supporter/TestResultExport.vue'
import ST_TestResultList from '@/pages/supporter/TestResultList.vue'

// service
import Index from '@/pages/service/Index.vue'
import TestAnserInput from '@/pages/service/TestAnserInput.vue'
import TestResultList from '@/pages/service/TestResultList.vue'
import TestResult from '@/pages/service/TestResult.vue'
import Profile from '@/pages/service/Profile.vue'
import VideoLibrary from '@/pages/service/VideoLibrary.vue'

//pinia
import { useAuth } from '@/stores/auth'

const requireAdmin = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
  if (authType == "admin") {
    next()
  }else{
    next({ name: 'Login' }) 
  }
}
const requireManager = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
  if (authType == "manager") {
    next()
  }else{
    next({ name: 'Login' }) 
  }
}
const requireStaff = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
  if (authType == "staff") {
    next()
  }else{
    next({ name: 'Login' }) 
  }
}
 const required = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
  if (authType == "") {
    next({ name: 'Login' }) 
  }else{
    next()
  }
 }
const requireAuth = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
    if(authType == "admin"){
      next({ name: 'S_Index' }) 
    }else if(authType == "manager"){
      next({ name: 'T_Index' }) 
    }else if(authType == "staff"){
      next({ name: 'ST_Index' }) 
    }else if(authType == "user"){
      next({ name: 'Index' }) 
    }else{
      next()
    }
}


const requireUser = (to, from, next) => {
  const authStore = useAuth()
  let authType = authStore.gotAuthType
  if (authType == "user") {
    next()
  }else{
    next({ name: 'Login' }) 
  }
}
const routes = [
  //errors
  {
    path: '/error',
    name: 'InternalServerError',
    component: InternalServerError,
  },

  //password
  {
    path: '/PasswordMail',
    name: 'PasswordMail',
    component: PasswordMail
  },
  {
    path: '/TokenError',
    name: 'TokenError',
    component: TokenError
  },

  // common
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    beforeEnter:requireAuth
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter:requireAuth
  },
  {
    path: '',
    name: 'Login',
    component: Login,
    beforeEnter:requireAuth
  },
  {
    path: '/InputMailAdress',
    name: 'InputMailAdress',
    component: InputMailAdress
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    beforeEnter:required
  },

  // serciveAdmin
  {
    path: '/ServiceAdmin/Index',
    name: 'S_Index',
    component: S_Index,
    beforeEnter: requireAdmin
  },
  {
    path: '/ServiceAdmin/TenantList',
    name: 'S_TenantList',
    component: S_TenantList,
    beforeEnter: requireAdmin
  },
  {
    path: '/ServiceAdmin/EditTenant',
    name: 'S_EditTenant',
    component: S_EditTenant,
    beforeEnter: requireAdmin
  },
  {
    path: '/ServiceAdmin/CreateTenant',
    name: 'S_CreateTenant',
    component: S_CreateTenant,
    beforeEnter: requireAdmin
  },
  {
    path: '/ServiceAdmin/EditTenantOwner',
    name: 'S_EditTenantOwner',
    component: S_EditTenantOwner,
    beforeEnter: requireAdmin
  },
  {
    path: '/ServiceAdmin/CreateTenantOwner',
    name: 'S_CreateTenantOwner',
    component: S_CreateTenantOwner,
    beforeEnter: requireAdmin
  },

  //tenantAdmin
  {
    path: '/Tenant/Index',
    name: 'T_Index',
    component: T_Index,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/CreateOutcome',
    name: 'T_CreateOutcome',
    component: T_CreateOutcome,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/EditOutcome',
    name: 'T_EditOutcome',
    component: T_EditOutcome,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/CreateStaff',
    name: 'T_CreateStaff',
    component: T_CreateStaff,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/EditStaff',
    name: 'T_EditStaff',
    component: T_EditStaff,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/OutcomeList',
    name: 'T_OutcomeList',
    component: T_OutcomeList,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/StaffList',
    name: 'T_StaffList',
    component: T_StaffList,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/VideoList',
    name: 'T_VideoList',
    component: T_VideoList,
    beforeRouteEnter: requireManager
  },
  {
    path: '/Tenant/VideoManagement',
    name: 'T_VideoManagement',
    component: T_VideoManagement,
    beforeRouteEnter: requireManager
  },

  //supporter
  {
    path: '/Supporter/Index',
    name: 'ST_Index',
    component: ST_Index,
    beforeRouteUpdate: requireStaff
    
  },
  {
    path: '/Supporter/CreateOutcome',
    name: 'ST_CreateOutcome',
    component: ST_CreateOutcome,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/EditOutcome',
    name: 'ST_EditOutcome',
    component: ST_EditOutcome,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/OutcomeList',
    name: 'ST_OutcomeList',
    component: ST_OutcomeList,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/OutcomeManegement',
    name: 'ST_OutcomeManegement',
    component: ST_OutcomeManegement,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/SetTest',
    name: 'ST_SetTest',
    component: ST_SetTest,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/TestResultChart',
    name: 'ST_TestResultChart',
    component: ST_TestResultChart,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/TestResultExport',
    name: 'ST_TestResultExport',
    component: ST_TestResultExport,
    beforeRouteEnter: requireStaff
  },
  {
    path: '/Supporter/TestResultList',
    name: 'ST_TestResultList',
    component: ST_TestResultList,
    beforeRouteEnter: requireStaff
  },

  //outcome
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    beforeEnter:requireUser
  },
  {
    path: '/TestAnserInput',
    name: 'TestAnserInput',
    component: TestAnserInput,
    beforeEnter:requireUser
  },
  {
    path: '/TestResultList',
    name: 'TestResultList',
    component: TestResultList,
    beforeEnter:requireUser
  },
  {
    path: '/TestResult',
    name: 'TestResult',
    component: TestResult,
    beforeEnter:requireUser
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    beforeEnter:requireUser
  }, 
  {
    path: '/VideoLibrary',
    name: 'VideoLibrary',
    component: VideoLibrary,
    beforeEnter:requireUser
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router