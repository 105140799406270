<script setup>
import {ref,defineProps,reactive} from 'vue'
const rules = reactive({
  password:[{ required: true, message: '必須項目です。', trigger: 'blur'},]
})
const props = defineProps({
  ruleForm:{
    password:String
  }
})
</script>

<script>
export default {
  name: 'PasswordForm'
}
</script>

<template>
  <el-form-item label="パスワード" prop="password" :rules="rules.password" >
    <el-input v-model="props.ruleForm.password" />
  </el-form-item>
</template>