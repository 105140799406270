<script setup>
import { reactive, ref ,computed} from 'vue'
import { FormInstance } from 'element-plus'
import { useServiceStore } from '@/stores/service'
import { useRoute, useRouter } from 'vue-router'
import AnswerTest from '@/components/service/test.vue'
import moment from "moment"
const serviceStore = useServiceStore()
const router = useRouter()
const route = useRoute()
let url = ""
let getType = ""
const scheduledSurvey = ref()

const setData = async() =>{
  let today = moment().format('YYYYMMDDhhmm')
  url = process.env.VUE_APP_BASEURL+"/user/interview?from_scheduled_date=" + today
  getType = "scheduledSurveyList"
  try{
    await serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }

  //enabled = falseのものは省く
  scheduledSurvey.value = serviceStore.gotScheduledSurveyList.filter(
    data => {
      return true
    }
    )

  url = process.env.VUE_APP_BASEURL+"/master/survey"
  getType = "masterSurvey"
  try{
    await serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }

  serviceStore.$subscribe((mutation, state) => {
    localStorage.setItem('service', JSON.stringify(state))
  })
}
setData()

const childRef = ref();
let surveyId = undefined
let masterId = undefined
let filteredSurveyDataByMasterId = undefined

const answerTest = async(row) =>{
  //①masterIdを使って、テストマスタからデータ取得
  masterId = row['master_id']
  surveyId = row['id']
  await serviceStore.filterSurveyMasterByMasterId(masterId)
  filteredSurveyDataByMasterId = serviceStore.gotFilteredSurveyDataByMasterId[0]

  //②結果画面で使用するマスタデータを取得しておく
  url = process.env.VUE_APP_BASEURL+"/master/survey?id=" + masterId
  getType = "masterSurveyDataByMasterId"
  try{
    await serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }

  //③const dataForComponentに入れて子コンポーネントに渡す
  const dataForComponent = filteredSurveyDataByMasterId
  await childRef.value.childMethod(dataForComponent)
}

const handleSubmit = async(testAnswer)=>{
  testAnswer.id = surveyId
  const data = testAnswer
  const url = process.env.VUE_APP_BASEURL+"/user/survey"
  try{
    await serviceStore.postData(url,data,"submitSurvey")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'TestResult'})
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テスト回答入力"/>
            <el-table row-key="no" :data="scheduledSurvey" >
              <el-table-column label="次回面談日">
                <template #default="props">
                  <p class="surveyItem">{{ $filters.displayDateFormat(props.row.scheduled_date) }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="surveys" label="テストタイプ" >
                <template #default="props">
                  <div v-for="survey in props.row.survey" direction="vertical">
                    <p class="surveyItem" >{{survey.name}}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="survey" label="宿題期限" >
                <template #default="props">
                  <div v-for="survey in props.row.survey" direction="vertical">
                    <p class="surveyItem">{{ $filters.displayDateFormat(survey.due_date) }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="survey" label="" >
                <template #default="props">
                  <div v-for="row in props.row.survey" direction="vertical" class="mt-2 mb-2">
                    <BasicButton @click="answerTest(row)" buttonName="テスト実施" :class="{hide: row.submitted_date} " />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          
          <AnswerTest 
            :selectedTest="dataForComponent"
            :surveyId = "surveyId"
            :masterId = "masterId"
            ref="childRef"
            @submit="handleSubmit"
          />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style scoped>
.surveyItem{
  padding-top:8px;
  padding-bottom:8px;
  margin-top:2px;
  margin-bottom:2px;
}

.hide{
  visibility: hidden;
}

</style>