<script setup>
import {defineProps,reactive} from 'vue'
const validateFullwidthKatakana = (rule, value, callback) => {
  if(value.match(/^[ァ-ヶー　]*$/)){    //"ー"の後ろの文字は全角。空文字を許容しない場合は(*→+)
    callback()
  }else{
    callback(new Error('フリガナは全角カタカナで入力してください。'))
  }
}
const rules = reactive({
  furigana: [
    { required: true, message: '必須項目です。', trigger: 'blur'},
    { min: 3, max: 20, message: '3字以上20字以下で入力してください。', trigger: 'blur' },
    { validator: validateFullwidthKatakana, trigger: 'blur'}
  ]
})
const props = defineProps({
  ruleForm:{
    furigana:String
  }
})
</script>

<script>
export default {
  name: 'FuriganaForm'
}
</script>

<template>
  <el-form-item label="フリガナ" prop="furigana" :rules="rules.furigana" >
    <el-input v-model="props.ruleForm.furigana" />
  </el-form-item>
</template>