<script setup>
import {storeToRefs} from 'pinia'
import { useTenantStore } from '@/stores/tenantAdmin'
import { ref  ,reactive} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
// import StatusFlg from '@/components/modules/statusFlg.vue'
const router = useRouter()
const route = useRoute()
const tenantStore = useTenantStore()
const ruleFormRef = ref()
const formSize = ref('default')
let referer = tenantStore.gotPageType
let formData = undefined
let pageName = ref()

const setData = ()=>{
  if(referer == "edit"){
    formData =tenantStore.gotVideoData[0]
    pageName.value = "ビデオコンテンツ編集"
  }else if(referer == "create"){
    formData = {"title":"" , "url":"" , "enabled":true}
    pageName.value = "ビデオコンテンツ新規追加"
  }
}
setData()

const ruleForm = reactive({
  title: formData.title,
  url: formData.url,
  statusFlg: formData.enabled
})

const rules = reactive({
  title: [
    { required: true, message: '必須項目です。', trigger: 'blur'}
  ],
  url: [
    { required: true, message: '必須項目です。', trigger: 'blur'},
  ],
  statusFlg: [
    { required: true, message: '必須項目です。', trigger: 'blur'},
  ]
})
let data = {}
const setSubmitData = ()=>{
  let url = ruleForm.url
  //動画を全画面で表示するため
  url = url.replace('watch?v=', 'embed/')

  //関連動画を表示させないため
  if(!url.endsWith(`?rel=0`)){
    url = url + "?rel=0"
  }
  data.title = ruleForm.title
  data.url = url
  data.enabled = ruleForm.statusFlg
} 

const submitData = async()=>{
  console.log(data)
  let url = ""
  try{
    if(referer == "edit"){
      url = process.env.VUE_APP_BASEURL+"/manager/video?id="+ formData.id
      await tenantStore.putData(data , url)
    }else if(referer == "create"){
      url = process.env.VUE_APP_BASEURL+"/manager/video" 
      await tenantStore.postData(data , url)
    }
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'T_VideoList'})
}

const submitForm = (formEl)=>{
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      setSubmitData()
      submitData()
    }else{
      return false
    }
  })
}

</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName :screenName="pageName"/>
              <el-form
                :model="ruleForm"
                label-width="150px"
                class="demo-ruleForm"
                :size="formSize"
                ref="ruleFormRef"
              >
                <el-form-item label="動画名" prop="title" :rules="rules.title">
                  <el-input v-model="ruleForm.title"  autocomplete="off" autosize/>
                </el-form-item>
                <el-form-item label="URL" prop="url" :rules="rules.url">
                  <el-input v-model="ruleForm.url" type="textarea" autocomplete="off" autosize />
                </el-form-item>
                <el-form-item prop="statusFlg" :rules="rules.statusFlg" label="相談者への表示">
                  <el-radio-group v-model="ruleForm.statusFlg">
                    <el-radio :label="true">{{"有効"}}</el-radio>
                    <el-radio :label="false">{{"無効"}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                
                <div class="bottom-button-area d-flex">
                  <el-form-item>
                    <BasicButton @click="$router.back()" buttonName="戻る" />
                    <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
                  </el-form-item>
                </div>
              </el-form>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>
