import { defineStore } from 'pinia'
import axios from '@/plugins/axios'
export const useStore = defineStore('serviceAdmin', {
  state: () => {
    return {
      tenantList:undefined,
      managerList:undefined,
      managerListFilterdById:undefined,
      editTenantId:undefined,
      editStaffId:undefined, 
      res:"",
      editTenantData:undefined,
      tenantStaffData:undefined,
      newTenant:{
        "name": "",
        "name_kana": "",
        "tel": "",
        "postal_code": "",
        "address1": "",
        "address1_kana": "",
        "address2": "",
        "address2_kana": "",
        "url": "",
        "enabled":true
      },
      clearTenant:{
        "name": "",
        "name_kana": "",
        "tel": "",
        "postal_code": "",
        "address1": "",
        "address1_kana": "",
        "address2": "",
        "address2_kana": "",
        "url": "",
        "enabled":true
      },
      newManager:{
        "name": "",
        "name_kana": "",
        "mail": "",
        "tel": "",
        "enabled": true,
        "password": "",
        "tenant_id": ""
      },
      clearManager:{
        "name": "",
        "name_kana": "",
        "mail": "",
        "tel": "",
        "password": "",
        "enabled": true,
        "tenant_id": ""
      },
      //テナント編集→テナント管理者編集遷移時
      editStaffData:undefined,
      transitionSource:""
    }
  },

  getters: {
    gotTenantList: (state)=>state.tenantList, 
    gotManagerList: (state)=>state.managerList,
    gotTenantDataById: (state) => state.editTenantData,
    gotManagerListFilterdByIdList: (state) => state.managerListFilterdById,
    gotEditTenantId: (state) => state.editTenantId,
    gotNewTenantData: (state) => state.newTenant,
    gotNewManager: (state) => state.newManager,
    gotEditStaffData: (state) => state.editStaffData,
    gotTransitionSource: (state) => state.transitionSource,
    gotEditStaffId: (state) => state.editStaffId,
  },

  actions: {
    // テナント一覧のデータを取得
    async getList(url,getType){
      const res = await axios.get(url)
      if(getType == "admin_tenant"){
        this.tenantList = res.data
      }else if(getType == "admin_manager"){
        this.managerList = res.data
      }else if(getType == "edit_admin_tenant"){
        this.setEditTenantData(res)
      }else if(getType == "edit_admin_staff"){
        this.setEditStaffData(res)
      }
    },
    setEditTenantData(res){
      let data = res.data.result[0]
      this.editTenantData =
      {
        "name": data.name,
        "name_kana": data.name_kana,
        "tel": data.tel,
        "postal_code": data.postal_code,
        "address1": data.address1,
        "address1_kana": data.address1_kana,
        "address2": data.address2,
        "address2_kana": data.address2_kana,
        "url": data.url,
        "enabled":data.enabled,
      }
    },
    setEditStaffData(res){
      let data = res.data.result[0]
      this.editStaffData =
      {
        "name": data.name,
        "name_kana": data.name_kana,
        "mail": data.mail,
        "tel": data.tel,
        "enabled": data.enabled,
        "tenant_id": this.tenantId,
      }
    },
    // テナントIDでテナント情報を１件に絞り込んで取得
    async filterTenantStaffDataById(tenantId){
      //マネージャーリスト
      const managerListTmp = this.managerList.result
      this.managerListFilterdById = managerListTmp.filter(obj => obj.tenant.id == tenantId)
    },
    handData(data,type){
      if(type == "editTenantId"){
        this.editTenantId = data
      }else if (type == "tenantDataById"){
        this.editTenantData = data
      }else if(type == "newTenantData"){
        this.newTenant = data
      }else if(type == "clearTenant"){
        this.newTenant=this.clearTenant
      }
    },
    //編集対象スタッフを１件に絞り込んで保持
    handEditStaffData(){
      const managerListTmp = this.managerList.result
      for (const [index, manager] of managerListTmp.entries()) {
        if (this.editStaffId == manager.id){
          this.editStaffData = manager
        }
      }
    },
    //更新処理
    async putData(url,data){
      const res = await axios.put(url,data)
    },
    //新規追加処理
    async postData(url,data){
      await axios.post(url,data)
    },
    async handTenantStaffId(tenantStaffId){
      this.editStaffId = tenantStaffId
    },
    addNewTenantOwner(newTenantOwner){
      this.staffList.push(newTenantOwner)
      this.newTenant.tenantOwner = this.newStaffList
    },
    async handNewTenantOwnerList(newTenantOwner){
      this.newStaffList.push(newTenantOwner)
      this.newTenant.tenantOwner = this.newStaffList
    },
    handTransitionSource(transitionSource){
      this.transitionSource = transitionSource
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})