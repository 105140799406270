<script setup>
import { reactive, ref } from 'vue'
import { useSupporterStore } from '@/stores/supporter'
import { useRoute, useRouter } from 'vue-router'
import OutcomeForm from '@/components/supporter/outcomeForm.vue'
const supporterStore = useSupporterStore()
const router = useRouter()
const route = useRoute()

const ruleForm = reactive({
  name: supporterStore.gotUserProfileForManagement[0].name,
  furigana: supporterStore.gotUserProfileForManagement[0].name_kana,
  tel: supporterStore.gotUserProfileForManagement[0].tel,
  mailAddress: supporterStore.gotUserProfileForManagement[0].mail,
  confirmMailAddress: supporterStore.gotUserProfileForManagement[0].mail,
  outcomeType: supporterStore.gotUserProfileForManagement[0].relationship,
  statusFlg: supporterStore.gotUserProfileForManagement[0].enabled
})
let outcomeData = undefined
const setOutcomeData = ()=>{
  outcomeData = {
    "name": ruleForm.name,
    "name_kana": ruleForm.furigana,
    "mail": ruleForm.mailAddress,
    "tel": ruleForm.tel,
    "birthday":supporterStore.gotUserProfileForManagement[0].birthday,
    "relationship": ruleForm.outcomeType,
    "character": supporterStore.gotUserProfileForManagement[0].character,
    "background":supporterStore.gotUserProfileForManagement[0].background,
    "enabled": ruleForm.statusFlg
  }
}

const updateUserProfile = async()=>{
  await setOutcomeData()
  try{
    const url = process.env.VUE_APP_BASEURL+"/staff/user?id=" + supporterStore.gotUserId
    await supporterStore.putData(outcomeData , url)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'ST_OutcomeManegement'})
}
const currentView = "edit"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者/相談者家族編集"/>
          <OutcomeForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            @submit="updateUserProfile()"
          />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>