<template>
  <!--トークンエラーページ-->
  <body class="" id="app">
    <main class="form-signin">
      <p class="fw-bold fs-4 text-secondary text-center">エラー</p>
      <p class="text-secondary text-center">URLが無効です。再度手続きを行ってください。</p>
    </main>
  </body>
</template>
<style scoped>
body {
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form-signin {
  width: 100%;
  max-width:750px;
  height:160px;
  padding: 15px;
  margin: 0 auto;
  background-color: #F7E6E5;
  border-radius: 4px;
}

</style>