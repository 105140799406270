<script setup>
import {ref,defineProps,reactive} from 'vue'
const isMatchMailaddress = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('確認用メールアドレスは必須項目です。'))
  } else if (value !== props.ruleForm.mailAddress) {
    callback(new Error("メールアドレスと確認用メールアドレスが一致していません。"))
  } else {
    callback()
  }
}
const rules = reactive({
    mailAddress: [
      { required: true, message: '必須項目です。', trigger: 'blur'},
      { type: "email", message: '正しいメールアドレスを入力してください。', trigger: 'blur' }
    ],
    confirmMailAddress: [
      { required: true, message: '必須項目です。', trigger: 'blur'},
      { validator: isMatchMailaddress, trigger: 'blur'}
    ]
})
const props = defineProps({
  ruleForm:{
    mailAddress:String,
    confirmMailAddress:String
  }
})
</script>

<script>
export default {
  name: 'MailForm'
}
</script>

<template>
  <el-form-item label="メールアドレス" prop="mailAddress" :rules="rules.mailAddress" >
    <el-input v-model="props.ruleForm.mailAddress" />
  </el-form-item>
  <el-form-item label="メールアドレス(確認用)" prop="confirmMailAddress" :rules="rules.confirmMailAddress" >
    <el-input v-model="props.ruleForm.confirmMailAddress" />
  </el-form-item>
</template>