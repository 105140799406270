import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
export const useChangePasswordStore = defineStore('changePassword', {
  state: () => {
    return {
    }
  },
  getters: {
  },
  actions: {
    //更新処理
    async putData(url,data){
      try{
        const res = await axios.put(url,data)
      }catch(e){
        console.log(e)
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
