<script setup>
import { useServiceStore } from '@/stores/service'
import { userMasterStore } from '@/stores/surveyMaster'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const serviceStore = useServiceStore()
const masterStore = userMasterStore()
let surveyMaster = undefined
let url = process.env.VUE_APP_BASEURL+"/user/survey?submitted_only=true"
let getType = "testResultList"
const getData = async()=>{
  // try{
    await serviceStore.getList(url,getType)

    //マスタサーベイの合計点の質問番号リスト
    url = process.env.VUE_APP_BASEURL+"/master/survey"
    getType = "masterSurvey"
    await serviceStore.getList(url,getType)
    surveyMaster = await serviceStore.gotSurveyMaster
    await setTotalScoreList()
  // }catch(error){
  //   switch (error.response?.status) {
  //     // case 401:
  //     //   localStorage.clear()
  //     //   $cookies.remove('authToken')
  //     //   $cookies.remove('expiresIn')
  //     //   await router.push({name: 'Login'})
  //     //   break
  //     case 422:
  //       break
  //     default:
  //       await router.push({name: 'InternalServerError'})
  //   }
  // }
}

let totalScoreList = []
const setTotalScoreList = async()=>{

  masterStore.$subscribe((mutation, state) => {
    localStorage.setItem('surveyMaster', JSON.stringify(state))
  })

  let array ={}
  
  surveyMaster.forEach(function(survey){
    //1つのテストを回す
    let questionId = undefined
    for(let index in survey.question.group){
      let questions = survey.question.group[index].questions
      
      questions.forEach(function(question){
        if(question.index == "display-none" && question.label == "total_score"){
          questionId = question.id
        } 
      })
    }
    array={
      "masterId": survey.id,
      "questionId": questionId //合計点が登録されている質問番号
    }
    totalScoreList.push(array)
  })
 await  masterStore.handData(totalScoreList)
}

const setData = async ()=>{
  await getData()
}
setData()
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="MainMenu"/>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>