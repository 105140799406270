<template>
  <el-button color="#69C0E8" style="color: white;">{{ buttonName }}</el-button>
</template>
<script>
export default {
  name: 'BasicButton',
  props: {
    buttonName: {
      type: String,
      default: "null",
    },
  }
}
</script>