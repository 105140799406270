<script setup>
import {storeToRefs} from 'pinia'
import { useTenantStore } from '@/stores/tenantAdmin'
import { ref , onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
const router = useRouter()
const route = useRoute()
const tenantStore = useTenantStore()
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="MainMenu"/>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>