<script setup>
import { useStore } from '@/stores/serviceAdmin'
import {onMounted,Prop,ref} from 'vue'
import { useRoute, useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const route = useRoute()
const handTransitionSource =() =>{
  const transitionSource = route.name
  store.handTransitionSource(transitionSource)
}
handTransitionSource()

onMounted(()=>{
  setData()
})

let tenantTableData = ref()
let managerTableData = ref()
const setData = async () => {
  try {
    let url = process.env.VUE_APP_BASEURL+"/admin/tenant"
    let getType = "admin_tenant"
    await store.getList(url,getType)

    url = process.env.VUE_APP_BASEURL+"/admin/manager"
    getType = "admin_manager"
    await store.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  tenantTableData.value = store.gotTenantList.result
  managerTableData.value = store.gotManagerList.result

  store.$subscribe((mutation, state) => {
  localStorage.setItem('serviceAdmin', JSON.stringify(state))
  })
}

const handleEdit = async(row) => {
  const tenantId = row.id
  store.handData(tenantId,"editTenantId")
  let url = process.env.VUE_APP_BASEURL+"/admin/tenant?id=" + store.gotEditTenantId
  try{
    await store.getList(url,"edit_admin_tenant")
    await store.filterTenantStaffDataById(tenantId)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }  
  await router.push({name: 'S_EditTenant'});
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テナント一覧"/>
          <div class="d-flex flex-row-reverse mb-4">
            <BasicButton @click="$router.push('/ServiceAdmin/CreateTenant')" buttonName="テナント新規追加" />
          </div>
          <el-table row-key="no" :data="tenantTableData" style="width: 100%">
            <el-table-column type="index" label="No" sortable column-key="no" />
            <el-table-column prop="name" sortable label="テナント"></el-table-column>
            <el-table-column prop="tel" label="電話番号" />
            <el-table-column label="テナント管理者">
              <template #default="props">
                <template v-for="managerList in managerTableData">
                  <div class="d-inline text-nowrap me-5" v-if="managerList.tenant.id == props.row.id">
                    {{managerList.name}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="テナント編集" width="120">
              <template #default="scope">
                <BasicButton @click="handleEdit(scope.row)" buttonName="編集" />
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
