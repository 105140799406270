<script setup>
import { reactive, ref } from 'vue'
import { useAuth } from '@/stores/auth'
import { useSideMenu } from '@/stores/menu'
import {useRouter} from 'vue-router'
const router = useRouter()
const authStore = useAuth()
const sideMenuStore = useSideMenu()
const userName = ref(authStore.gotMemberInfo.name)
const Logout = async() =>{ 
  await authStore.logout()
  await sideMenuStore.handData("","menuType")
  await sideMenuStore.handData(undefined,"sidemenuList")
  authStore.$subscribe((mutation, state) => {
    localStorage.setItem('auth', JSON.stringify(state))
  })

  if(!authStore.isLoggedIn){
    router.push({name:'Login'})
  }
}
</script>

<script>
export default {
  name: 'TheHeading'
}
</script>

<template>
  <div class="float-end">
    <a id="loginUser" @click="$router.push('/ChangePassword')">{{userName}}</a>
    <BasicButton @click="Logout" buttonName="ログアウト" />
  </div>
</template>

<style scoped>
#loginUser{
  display: inline-block;
  vertical-align: middle;
  margin: 20px;
}
#logoutBtn{
  margin: 20px;
}
</style>
