<script setup>
import {ref,defineProps,reactive,defineEmits,computed} from 'vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import StatusFlg from '@/components/modules/statusFlg.vue'
const formSize = ref('default')
const ruleFormRef = ref()
const props = defineProps({
  ruleForm:{
    tenantName:String,
    tenantStatus:Boolean,
    tel:String,
    postNumber:String,
    address:String,
    url:String,
  },
  tenantOwnerList:Object,
  parentComponent:String 
})
const hasTenantOwner = (rule, value, callback) => {
  if (props.tenantOwnerList.length === 0) {
    callback(new Error('テナント管理者は必須項目です。'))
  }else {
    callback()
  }
}
const validatePostNumberFormat = (rule, value, callback) => {
  let postNumber = value.toString()
  if (postNumber.match(/^[0-9]{7}$/)) {
    callback()
  }else {
    callback(new Error('郵便番号は半角数字7桁で入力してください。'))
  }
}
import { required } from '@/util/index'
const rules = reactive({
  tenantName: [required,
               { min: 3, max: 20, message: '3字以上20字以下で入力してください。', trigger: 'blur' }],
  postNumber: [required,
               { validator: validatePostNumberFormat, trigger: 'blur'}],
  address: [required],
  url: [required,
        { type: "url", message: '正しいURLを入力してください。', trigger: 'blur' }],
  tenantOwnerList:[{ required: true, validator: hasTenantOwner, trigger: 'blur'}]
})

const emit = defineEmits(['createTenantOwner','editTenantOwner','submit']) 
const createTenantOwner = async()=>{
  emit('createTenantOwner')
}

const editTenantOwner = async(scope) => {
  const tenantStaffId = scope.row.id
  emit('editTenantOwner',tenantStaffId)
}

const submitForm = (formEl) =>{
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      emit('submit')
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}
</script>
<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="150px"
    class="demo-ruleForm"
    :size="formSize"
    :rules="rules"
  >
    <el-form-item label="テナント名" prop="tenantName" >
      <el-input  v-model="props.ruleForm.tenantName" />
    </el-form-item>
    <el-form-item v-show = "parentComponent == `edit`" label="テナント管理者" v-model="props.tenantOwnerList" prop="tenantOwnerList">
      <BasicButton @click="createTenantOwner" buttonName="テナント管理者新規追加" />
      <el-table ref="tableRef" row-key="no" :data="props.tenantOwnerList" >
        <el-table-column type="index" />
        <el-table-column prop="name" label="テナント管理者"></el-table-column>
        <el-table-column fixed="right" label="" width="120">
          <template #default="scope">
          <el-button color="#69C0E8" style="color: white;" @click="editTenantOwner(scope)">編集</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <StatusFlg v-model="props.ruleForm.tenantStatus" :ruleForm ="ruleForm" :model="ruleForm" statusType="tenantStatus" label="テナント状態" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <el-form-item label="郵便番号" prop="postNumber" >
      <el-input v-model="props.ruleForm.postNumber"/>
    </el-form-item>
    <el-form-item label="住所" prop="address">
      <el-input v-model="props.ruleForm.address" />
    </el-form-item>
    <el-form-item label="URL" prop="url">
      <el-input v-model.url="props.ruleForm.url" />
    </el-form-item>
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton v-if="parentComponent == `edit`" @click="$router.push('/ServiceAdmin/TenantList')" buttonName="戻る" />
        <BasicButton v-else-if="parentComponent == `create`" @click="$emit('back')" buttonName="戻る" />
        <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
        <!-- <BasicButton v-if="parentComponent == `edit`" @click="$emit('delete')" buttonName="削除" /> -->
      </el-form-item>
    </div>
  </el-form>
</template>

