<script setup>
import { reactive, ref } from 'vue'
import { FormInstance } from 'element-plus'
import { useTenantStore } from '@/stores/tenantAdmin'
import { useRoute, useRouter } from 'vue-router'
import NameForm from '@/components/modules/nameForm.vue'
import FuriganaForm from '@/components/modules/furiganaForm.vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import MailForm from '@/components/modules/mailForm.vue'
const formSize = ref('default')
const ruleFormRef = ref()
const props = defineProps({
  ruleForm:{
    name: String,
    furigana: String,
    tel: Number,
    mailAddress: String,
    confirmMailAddress: String,
    birthday: String,
    personality: String,
    upbringing:String
  }
})

const emit = defineEmits(['submit']) 
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      emit('submit')
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}
</script>
<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="150px"
    class="demo-ruleForm"
    :size="formSize"
  >
    <NameForm v-model="props.ruleForm.name" :ruleForm ="ruleForm" :model="ruleForm" />
    <FuriganaForm v-model="props.ruleForm.furigana" :ruleForm ="ruleForm" :model="ruleForm" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <MailForm 
      v-model:mailAddress="props.ruleForm.mailAddress"
      v-model:confirmMailAddress="props.ruleForm.confirmMailAddress" 
      :ruleForm ="ruleForm" :model="ruleForm" />
    <el-form-item label="生年月日" prop="birthday">
      <el-date-picker v-model="ruleForm.birthday" type="date" placeholder="" format="YYYY/MM/DD" style="width:100%" />
    </el-form-item>
    <el-form-item label="性格" prop="personality">
      <el-input v-model="ruleForm.personality" type="textarea" autocomplete="off" autosize/>
    </el-form-item>
    <el-form-item label="生い立ち" prop="upbringing">
      <el-input v-model="ruleForm.upbringing" type="textarea" autocomplete="off" autosize />
    </el-form-item>
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton @click="$router.back()" buttonName="戻る" />
        <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
      </el-form-item>
    </div>
  </el-form>
</template>