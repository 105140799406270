<script setup>
import { reactive, ref } from 'vue'
import {useRouter} from 'vue-router'
import { useAuth } from '@/stores/auth'
import { useSideMenu } from '@/stores/menu'
const sideMenuStore = useSideMenu()
const labelPosition = ref('top')
const router = useRouter()
const authStore = useAuth()
const menuContents ={
  menuList:[
  {
    menuType:"admin",
    content:[
      {id: 1, title: "MainMenu", url: "/ServiceAdmin/Index"},
      {id: 2, title: "テナント一覧", url: "/ServiceAdmin/TenantList"}
    ]
  },
  {
    menuType:"manager",
    content:[
      {id: 1, title: "MainMenu", url: "/Tenant/Index"},
      {id: 2, title: "スタッフ一覧", url: "/Tenant/StaffList"},
      {id: 3, title: "相談者/相談者家族管理", url: "/Tenant/OutcomeList"},
      {id: 4, title: "ビデオライブラリ管理", url: "/Tenant/VideoList"},
    ]
  },
  {
    menuType:"staff",
    content:[
      {id: 1, title: "MainMenu", url: "/Supporter/Index"},
      {id: 2, title: "相談者一覧", url: "/Supporter/OutcomeList"},
      {id: 3, title: "相談者/相談者家族管理", url: "/Supporter/OutcomeManegement"},
      {id: 4, title: "テスト結果エクスポート", url: "/Supporter/TestResultExport"}
    ]
  },
  {
    menuType:"user",
    content:[
      {id: 1, title: "MainMenu", url: "/Index"},
      {id: 2, title: "テスト回答入力", url: "/TestAnserInput"},
      {id: 3, title: "過去試験結果一覧", url: "/TestResultList"},
      {id: 4, title: "プロフィール設定", url: "/Profile"},
      {id: 5, title: "ビデオライブラリ", url: "/VideoLibrary"}
    ]
  }
  ]
}

const errRef = reactive({
  errMessage :""
})

const img = ref()
img.value = require('@/assets/img/khj-logo.png')

const ruleFormRef = ref()
const ruleForm = reactive({
  mail: "",
  password: ""
})

let isInputValue = false
const validateRequired = () =>{
  if (!ruleForm.mail && !ruleForm.password) {
    errRef.errMessage="メールアドレスとパスワードを入力してください"
  }else if(!ruleForm.mail){
    errRef.errMessage="メールアドレスを入力してください"
  } else if(!ruleForm.password){
    errRef.errMessage="パスワードを入力してください"
  }else {
    isInputValue = true
    setInfoForLogin()
  }
}

let infoForLogin = null
const setInfoForLogin = ()=> {
  if(isInputValue){
    infoForLogin = {
      "username" : ruleForm.mail,
      "password" : ruleForm.password,
    }
  }
}

//設定されているauthトークンの1分前にリフレッシュトークンを取得し、cookieにセットし直す
const getRefreshToken=(expiresIn)=>{
  let timerId = setInterval(function () {
  if(!$cookies.isKey('authToken')){
    clearInterval(timerId)
  }
    useAuth().putRefreshToken()
 }.bind(this), 840000) 
  // }.bind(this), 1000 * expiresIn - 60000) 
  //1秒 * 60秒 * expire_in(分) - 60秒(60000)
}

const authenticate = async()=>{
  try{
    await authStore.authenticate(infoForLogin)
  }catch(error){
    // switch (error.response?.status) {
      // case 401:
      //   break
      // default:
        await router.push({name: 'InternalServerError'})
  }


  if (authStore.isLoggedIn){
    await setAuth()
    await setSideMenu()
    let expiresIn = $cookies.get('expiresIn')
    await getRefreshToken(expiresIn)
    await router.push({name: pageName})
  }else{
    console.log("OUTS")
  }
}

let auth = undefined
let pageName = ""
const setAuth =() =>{
  //メンバーロール (1: user, 2: staff, 4: manager, 8: admin)
  if (authStore.gotRole == 8) {
    pageName = "S_Index"
    auth = "admin"
  }else if(authStore.gotRole == 4){
    pageName = "T_Index"
    auth = "manager"
  } else if(authStore.gotRole == 2){
    pageName = "ST_Index"
    auth = "staff"
  }else if(authStore.gotRole == 1){
    pageName = "Index"
    auth = "user"
  }else {
    errRef.errMessage="正しいメールアドレスとパスワードを入力してください"
    return
  }
}
const setSideMenu = () =>{
  let sidemenuList = []
  let sidemenu = undefined
  let typeOfMenu = ""
  let authType = undefined

  for (let item in menuContents.menuList){
    if(menuContents.menuList[item].menuType == auth){
      sidemenu = menuContents.menuList[item].content
      typeOfMenu = menuContents.menuList[item].menuType
      authType =auth
    }
  }
  sideMenuStore.$subscribe((mutation, state) => {
  localStorage.setItem('sidemenu', JSON.stringify(state))
  })
  sideMenuStore.handData(sidemenu,"sidemenu")
  sideMenuStore.handData(typeOfMenu,"menuType")
  authStore.handData(authType,"authType")
}

const judgeLogin = (event)=>{
  validateRequired()
  if(isInputValue){
    authenticate()
  }
}
</script>

<template>
  <body class="text-center" id="app">
    <router-view />
    <main class="form-signin">
      <p class="fw-bold fs-4 text-secondary">ログイン</p>
      <!-- <img :src="img" alt="Logo" class="header__logo"> -->
      <div class="messageArea mt-4"><p class="error">{{errRef.errMessage}}</p></div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :label-position="labelPosition"
        label-width="150px"
        style="max-width: 460px"
      >
        <el-form-item label="メールアドレス">
          <el-input v-model="ruleForm.mail"  placeholder="name@example.com"/>
        </el-form-item>
        <el-form-item label="パスワード">
          <el-input v-model="ruleForm.password" type="password" show-password placeholder="パスワード" />
        </el-form-item>
        <BasicButton @click="judgeLogin" buttonName="ログイン" />
        <div class="mt-2">
          <router-link to="/InputMailAdress" class="text-secondary">パスワードをお忘れの方はこちら</router-link>
        </div>
      </el-form>
      
    </main>
  </body>
</template>
<style scoped>
body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form-signin {
  width: 100%;
  max-width:400px;
  height:500px;
  padding: 15px;
  margin: 0 auto;
  background-color: #E8F5FB;
  border-radius: 4px;
}
#forgot-password{
  padding: 10px;
}
.error { color: red; }
.messageArea{
  height: 40px;
}
.header__logo{
  width: 100px;
  height:100px;
}
</style>


