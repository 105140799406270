import axios from '@/plugins/axios'
import { defineStore } from 'pinia'

export const useServiceStore = defineStore('service', {
  state: () => {
    return {
      userProfile:undefined,
      videoList:undefined,
      scheduledSurveyList:undefined,
      testResultList:undefined,
      surveyMaster:undefined,
      filteredSurveyDataByMasterId:undefined,
      testAnswerList:undefined,
      masterSurveyDataByMasterId:undefined,
      totalScoreList:undefined
    }
  },
  getters: {
    gotUserProfile: (state)=>state.userProfile,
    gotVideoList: (state)=>state.videoList,
    gotScheduledSurveyList: (state)=>state.scheduledSurveyList,
    gotTestResultList: (state)=>state.testResultList,
    gotFilteredSurveyDataByMasterId: (state)=>state.filteredSurveyDataByMasterId,
    gotTestAnswerList: (state)=>state.testAnswerList,
    gotMasterSurveyDataByMasterId: (state)=>state.masterSurveyDataByMasterId,
    gotSurveyMaster: (state)=>state.surveyMaster,
    gotTotalScoreList:(state)=>state.totalScoreList,
  },
  actions: {
    async getList(url,getType){
      const res = await axios.get(url)
      if(getType == "userProfile"){
        this.userProfile = res.data.result
      }else if(getType == "videoList"){
        this.videoList = res.data.result
      }else if(getType == "scheduledSurveyList"){
        this.scheduledSurveyList = res.data.result
      }else if(getType == "testResultList"){ 
        this.testResultList = res.data.result
      }else if(getType == "masterSurvey"){
        this.surveyMaster = res.data.result
      }else if(getType == "masterSurveyDataByMasterId"){
        this.masterSurveyDataByMasterId = res.data.result
      }
    },
    //登録処理
    async postData(url,data,type){
      const res = await axios.post(url,data)
      if(type == "submitSurvey" ){
        this.testAnswerList = res.data.result
      }
    },
    //更新処理
    async putData(url,data){
      const res = await axios.put(url,data)
    },
    filterSurveyMasterByMasterId(masterId){
      this.filteredSurveyDataByMasterId = this.surveyMaster.filter(
        data => {
          if(data.id == masterId){
              return true
            }
        })
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
