<script setup>
import { reactive, ref } from 'vue'
import { useTenantStore } from '@/stores/tenantAdmin'
import { useRoute, useRouter } from 'vue-router'
import OutcomeForm from '@/components/tenantAdmin/outcomeForm.vue'
const tenantStore = useTenantStore()
const router = useRouter()
const route = useRoute()
const ruleForm = reactive({
  name: tenantStore.gotEditUserData.name,
  furigana: tenantStore.gotEditUserData.name_kana,
  tel: tenantStore.gotEditUserData.tel,
  mailAddress: tenantStore.gotEditUserData.mail,
  confirmMailAddress: tenantStore.gotEditUserData.mail,
  outcomeType: tenantStore.gotEditUserData.relationship,
  statusFlg: tenantStore.gotEditUserData.enabled,
  staffId: tenantStore.gotEditUserData.staff.id
})

let outcomeDataForSubmit = undefined
const setTenantDataForSubmit = ()=>{
  const userId = tenantStore.gotEditUserData.id

  outcomeDataForSubmit = 
    {
      "name": ruleForm.name,
      "name_kana": ruleForm.furigana,
      "mail": ruleForm.mailAddress,
      "tel": ruleForm.tel,
      "birthday": tenantStore.gotEditUserData.birthday,
      "relationship": ruleForm.outcomeType,
      "character": tenantStore.gotEditUserData.character,
      "background": tenantStore.gotEditUserData.background,
      "enabled": true,
      "staff_id": ruleForm.staffId
    }
}
const handleSubmit = async()=>{
  const url = process.env.VUE_APP_BASEURL+"/manager/user?id=" + tenantStore.gotUserId
  await setTenantDataForSubmit()
  try{
    await tenantStore.putData(outcomeDataForSubmit , url)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'T_OutcomeList'})
}
const currentView = "edit"
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="相談者/相談者家族編集"/>
          <OutcomeForm
            :parentComponent = "currentView"
            :ruleForm="ruleForm"
            :staffList = "tenantStore.gotStaffList"
            @submit="handleSubmit"
          />
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>
