import { defineStore } from 'pinia'
import axios from 'axios'

export const useAuth = defineStore('auth', {
  state: () => {
    return {
      memberInfo:null,
      authType:undefined,
      authToken:undefined,
      role:undefined
    }
  },
  getters: {
    gotMemberInfo: (state)=>state.memberInfo, 
    isLoggedIn: (state)=> Boolean(state.memberInfo), //userId に何らかの値がセットされている場合に true を返す
    gotAuthType :(state)=>state.authType,
    gotAuthToken :(state)=>state.authToken,
    gotRole :(state)=>state.role

  },
  actions: {
    handData(data,type){
      if(type == "infoForLogin"){
        this.infoForLogin = data
      }else if(type == "memberInfo"){
        this.memberInfo = data
      }else if(type ="authType"){
        this.authType = data
      }
    },

    async authenticate(infoForLogin){
      let url = process.env.VUE_APP_BASEURL+"/member/authenticate"
      const data = new FormData();
      data.append("username", infoForLogin.username);
      data.append("password", infoForLogin.password);

      //メンバー認証
      let res = await axios.post(url , data)

      //authトークンとアクセストークンの有効時間をクッキーで保持
      $cookies.set('authToken', res.data.access_token);
      $cookies.set('expiresIn', res.data.expires_in);
      let authToken = $cookies.get('authToken')
      res = await axios.get(process.env.VUE_APP_BASEURL+"/member/profile",{
        headers:{
          'Authorization' : `Bearer ${authToken}`
        }
      }
      )

      let memberInfo = res.data.result
      this.handData(memberInfo, "memberInfo")
      this.role = memberInfo.role
    },
    async putRefreshToken(){
      let url = process.env.VUE_APP_BASEURL+"/member/refresh_token"
      let authToken = $cookies.get('authToken')
      const res = await axios.put(url,{},{
        headers:{
          'Authorization' : `Bearer ${authToken}`
        }
      })
      $cookies.set('authToken', res.data.access_token);
      $cookies.set('expiresIn', res.data.expires_in);
    },
    async logout(){
      localStorage.clear() //ローカルストレージを全て削除する
      this.handData(undefined,"authType")
      this.handData(null,"memberInfo")
      $cookies.remove('authToken')
      $cookies.remove('expiresIn')
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})
