<script setup>
import { useSupporterStore } from '@/stores/supporter'
import {storeToRefs} from 'pinia'
import {onMounted,Prop,ref,reactive} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from "moment"
const router = useRouter()
const route = useRoute()
const supporterStore = useSupporterStore()
let url =""
let getType = ""
let name = ref()
let interviewListForDisplay = ref()
let userProfile = undefined
let interviewListData = undefined
let surveyMaster = undefined

const displayDateFormat =(value)=> {
  var formatDate = moment.utc(value).local().format('YYYY/MM/DD')
  return  formatDate
}

const setData = () =>{
  let userProfileAndInterveiwData = supporterStore.gotUserProfileAndInterveiwData
  if(supporterStore.gotUserProfileAndInterveiwData.length ==0){
    userProfile = {
      "id":supporterStore.gotUserProfileData.id,
      "name":supporterStore.gotUserProfileData.name,
      "relationship":supporterStore.gotUserProfileData.relationship,
    }
    interviewListForDisplay.value= [{ scheduled_date: '', enabled:true, hasSubmittedSurvey:false , survey: [ { due_date: '', enabled:true ,master_id:''} ]} ]
  }else{
    userProfile = userProfileAndInterveiwData[0].user
      userProfileAndInterveiwData = userProfileAndInterveiwData.map(function(interview){
        //この中でフィルターする処理が必要そう
        interview.scheduled_date = displayDateFormat(interview.scheduled_date)
        interview.hasSubmittedSurvey =false
        for(let index in interview.survey){
          interview.survey[index].due_date = displayDateFormat(interview.survey[index].due_date)
          if(interview.survey[index].submitted_date){
            interview.hasSubmittedSurvey =true
          }
        }
      return interview
      })
      interviewListForDisplay.value = userProfileAndInterveiwData.sort(function(a, b){
        return moment(a.scheduled_date) - moment(b.scheduled_date);
      })
  }
  name.value = userProfile.name
  surveyMaster = supporterStore.gotSurveyMaster
}
setData()

const disabledDate = (time) => {
  return time.getTime() < Date.now()
}

let outcomeType = ref()
const setOutComeType = () =>{
  if (userProfile.relationship == "1"){
    outcomeType.value ="本人"
  }else if(userProfile.relationship == "2"){
    outcomeType.value ="相談者家族"
  }
}
setOutComeType()

let testTypeOptions = []
const surveyListFilteredByCategory = supporterStore.gotSurveyListFilteredByCategory
const setSurveyTypeOptions = () =>{
  let testType = undefined
  for(const el of surveyListFilteredByCategory) {
    testType = {value: el.id, label: el.name}
    testTypeOptions.push(testType)
  }
}
setSurveyTypeOptions()

const addRow = ()=>{
  interviewListForDisplay.value.push( { scheduled_date: '', enabled:true, hasSubmittedSurvey:false , survey: [ { due_date: '', enabled:true ,master_id:''} ]} )
}

const deleteRow = (interview,index,surveyIndex,interview_survey)=>{
  if(interview.id !== "" && typeof interview.id !== "undefined"){
    interview.enabled = false
  }else if(typeof interview.id === "undefined"){
    interviewListForDisplay.value.splice(index, 1)
  }
}

//①最初に必須項目のバリデーション
import { ElNotification } from 'element-plus'
let isInputRequiredValues = false
const validateRequiredValues = ()=>{
  for(let interview in interviewListForDisplay.value){
    if(interviewListForDisplay.value[interview].scheduled_date){
      for(let survey in interviewListForDisplay.value[interview].survey){
        if(!interviewListForDisplay.value[interview].survey[survey].due_date || !interviewListForDisplay.value[interview].survey[survey].master_id){
          ElNotification({
            title: '',
            message: 'テストタイプ・宿題期限を全て設定してください。',
            type: 'error',
            onClick: event.preventDefault()
          })
          return
        }
      }
    }else{
      ElNotification({
        title: '',
        message: '面談日・テストタイプ・宿題期限を全て設定してください。',
        type: 'error',
        onClick: event.preventDefault()
      })
      return
    }
  }
  isInputRequiredValues = true
}
//ISO8061にフォーマットする
let dateConvertedToISO8061 = ""
const FormatToISO8061 = (date) =>{
  return new Date(date).toISOString()
}

//②面談日をISO8061にフォーマット
const formatDate = ()=>{
  
  for(let interview in interviewListForDisplay.value){
    if(typeof interviewListForDisplay.value[interview].scheduled_date !== "string" ){
      interviewListForDisplay.value[interview].scheduled_date =  FormatToISO8061(interviewListForDisplay.value[interview].scheduled_date)

    }
    for(let survey in interviewListForDisplay.value[interview].survey){
      if(typeof interviewListForDisplay.value[interview].survey[survey].due_date !== "string" ){
        interviewListForDisplay.value[interview].survey[survey].due_date = FormatToISO8061(interviewListForDisplay.value[interview].survey[survey].due_date)
      }
    }
  }
}

//③面談日が同じデータをグループ化
let interviewListGroupByIntervewDate = undefined
const groupByIntervewDate = (list, key) =>{
  return list.reduce(function(previousValue, currentValue) {
    (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(currentValue)
    return previousValue
  }, {})
}

//④データを登録用に成形する
const setSurveyListForSubmit = () =>{
  let surveyDataForSubmit = {}
  for(let interviewDate in interviewListGroupByIntervewDate){ //インタビューリストを回す
    let surveyListForSubmit = []
    for(let survey in interviewListGroupByIntervewDate[interviewDate][0].survey){ //サーベイリストを回す
      surveyDataForSubmit = interviewListGroupByIntervewDate[interviewDate][0].survey[survey]
        surveyDataForSubmit.master_id = interviewListGroupByIntervewDate[interviewDate][0].survey[survey].master_id
        surveyDataForSubmit.due_date = interviewListGroupByIntervewDate[interviewDate][0].survey[survey].due_date
        surveyDataForSubmit.enabled = true
        surveyListForSubmit.push(surveyDataForSubmit)
    }
    interviewListGroupByIntervewDate[interviewDate][0].survey = surveyListForSubmit
  }
}

//⑤登録に使うjsonを作成
let interviewListForCreate = undefined
let interviewListForUpdate = undefined
const setInterviewListForSubmit = () =>{
  interviewListForCreate = []
  interviewListForUpdate = []
  
  let interviewDataForSubmit = undefined
  let interviewIdList = []
  let userId = undefined
  supporterStore.gotUserProfileAndInterveiwData.forEach(function(data){
    data.survey.forEach(function(surveyData){
      if(surveyData.submitted_date){
        interviewIdList.push(data.id) //面談ID
      }
    })
  })  
    for(let interviewDate in interviewListGroupByIntervewDate){
      let surveyList = []
      let result = {
        "data":{}
      }
      result.data.scheduled_date = FormatToISO8061(interviewDate) //面談日
      interviewListGroupByIntervewDate[interviewDate].forEach(function(interview){
        if(interview.id){
          result.id = interview.id
        }
        result.data.enabled = interview.enabled//面談日
        if(!interviewIdList.includes(interview.id)){
          interview.survey.forEach(function(survey){
            let newsurvey = {
              "enabled":survey.enabled,
              "master_id":survey.master_id
            }
            newsurvey.due_date = FormatToISO8061(survey.due_date)
            surveyList.push(newsurvey)
            })
        }
      })
      if(surveyList.length !== 0){
        result.data.survey = surveyList
      }
      
    if(result.id){
      interviewListForUpdate.push(result)
    }else if(!result.id){
      result.data.user_id = userProfile.id
      result.data.enabled = true //面談日
      interviewListForCreate.push(result)
    }
    }
}

const removeHasSubmmitedSurveyFromInterviewList = ()=>{
  for(let index in interviewListForDisplay.value){
    if(interviewListForDisplay.value[index].hasSubmittedSurvey == true){
      delete interviewListForDisplay.value[index]
    }
  }
}

//登録ボタン押下
const handleSubmit = async() =>{
  await validateRequiredValues()
  let data = undefined
  if(isInputRequiredValues){
    
    await formatDate()
    await removeHasSubmmitedSurveyFromInterviewList()
    if(interviewListForDisplay.value){
      interviewListGroupByIntervewDate = await groupByIntervewDate(interviewListForDisplay.value, 'scheduled_date')
     await setSurveyListForSubmit()
      await setInterviewListForSubmit()
    }

    try{
      if(interviewListForCreate.length !== 0){
        let url = process.env.VUE_APP_BASEURL+"/staff/interview"
        for(let interviewData in interviewListForCreate){
          data = interviewListForCreate[interviewData].data
          await supporterStore.postData(data , url)
        }
      }
      if(interviewListForUpdate.length !== 0){
        for(let interviewData in interviewListForUpdate){
          let url = process.env.VUE_APP_BASEURL+"/staff/interview?id=" + interviewListForUpdate[interviewData].id
          data = interviewListForUpdate[interviewData].data
          await supporterStore.putData(data , url)
        }
      }
    }catch(error){
      switch (error.response?.status) {
        case 401:
          localStorage.clear()
          $cookies.remove('authToken')
          $cookies.remove('expiresIn')
          await router.push({name: 'Login'})
          break
        case 422:
          break
        default:
          await router.push({name: 'InternalServerError'})
      }
    }
    await router.push({name: 'ST_OutcomeList'})
  }
}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="次回テスト設定"/>
          <p class="fs-5 fw-bolder text">{{name}}　({{outcomeType}})</p>
          <div class="mt-4">
            <table style="width:100%;" >
              <thead>
                <tr>
                  <th style="color: #909399; font-size:14px;">次回面談日</th>
                  <th style="color: #909399; font-size:14px;">テストタイプ</th>
                  <th style="color: #909399; font-size:14px;">宿題期限</th>
                </tr>
              </thead>
              <tbody>
                
                <template v-for="(interview, index) in interviewListForDisplay" v-bind:key="index">
                <!-- {{interview]}} -->
                  <template v-for="(setSurvey, surveyIndex) in interview.survey" v-bind:key="setSurvey.id" >
                    <tr v-show="interview.enabled">
                      <td v-bind:class="{'visible': surveyIndex == 0, 'invisible': surveyIndex !== 0}">
                        <!--TODO-1：行が非活性になる条件を見直す必要あり-->
                        <!--TODO-2：表示条件の見直し-->
                        <el-date-picker 
                          v-model="interview.scheduled_date" 
                          :disabled="interview.hasSubmittedSurvey" 
                          type="date" 
                          :disabled-date="disabledDate" 
                          placeholder="" 
                          format="YYYY/MM/DD"
                          style="width:95%" />
                      </td>
                      <td>
                        <el-select 
                          v-model="setSurvey.master_id"
                          :disabled="interview.hasSubmittedSurvey" 
                          style="width:95%"
                          placeholder=" "
                        >
                        <el-option
                         
                          v-for="item in testTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value" />
                        </el-select>
                      </td>
                      <td>
                        <el-date-picker 
                          v-model="setSurvey.due_date" 
                          :disabled="interview.hasSubmittedSurvey" 
                          type="date" 
                          :disabled-date="disabledDate"
                          placeholder="" 
                          format="YYYY/MM/DD"
                          style="width:95%" />
                      </td>
                      <td ><BasicButton v-on:click="deleteRow(interview,index,surveyIndex,interview.survey)" buttonName="削除" :disabled="interview.hasSubmittedSurvey"/></td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <BasicButton class="mt-4" v-on:click="addRow" buttonName="行を追加" />
            </table>
          </div>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="router.back()" buttonName="戻る" />
            <BasicButton @click="handleSubmit" buttonName="登録" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
