<script setup>
import { useSupporterStore } from '@/stores/supporter'
import { userMasterStore } from '@/stores/surveyMaster'
import { ref } from 'vue'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-moment';
import { collapseItemProps } from 'element-plus';
Chart.register(...registerables);

const supporterStore = useSupporterStore()
const userProfileData = supporterStore.gotUserProfileData
const masterStore =  userMasterStore()

let name = ref( supporterStore.gotUserProfileData.name)
let userId = supporterStore.gotUserProfileData.id

console.log("USER:" ,userId);
let relationship = undefined
const setProfileData = () =>{
  if (userProfileData.relationship == 1){
    relationship ="本人"
  }else if(userProfileData.relationship == 2){
    relationship ="相談者家族"
  }
}
setProfileData()

const testResultList = ref()
const testType = supporterStore.gotSurveyResultDataByMasterId[0].name
let surveyResultAr = supporterStore.gotSurveyMasterByMasterId.filter(function(master){
  console.log("MASTER:", master);
})
console.table("SurveyResult:", supporterStore.gotSurveyMasterByMasterId)
console.table("sAR         :", surveyResultAr)
console.log(surveyResultAr)
let totalScoreList = undefined
const setDataForDisplay = ()=>{
  let tmpArray = []
  //testResultList.value = supporterStore.gotSurveyResultDataByMasterId.map(function(result){
  tmpArray = supporterStore.gotSurveyResultDataByMasterId.map(function(result){

    console.log("RESULT::",result)
    let score = undefined
    let questionId = undefined
    totalScoreList = masterStore.gotTotalScoreList
    totalScoreList.forEach(function(master){
      if(master.masterId == result.master_id){
        questionId = master.questionId
        return
      }
    })

    result.answer.forEach(function(answer){
      if(answer.question_id == questionId){
        score = answer.score
      }
    })
    result.score = score //APIから返ってきたscoreを上書き
    return result
  })
  // 該当ユーザの結果だけにフィルター
  let filteredArray = tmpArray.filter(function(item){
    return item.user_id == userId
  })
  testResultList.value = filteredArray
}
setDataForDisplay()



//画面で問題を表示するためにマスタサーベイを取得
//const surveyMasterByMasterId = ref(supporterStore.gotSurveyMasterByMasterId)
const testResultForModal = ref()

//モーダル表示する実際のテスト結果
let surveyMasterDataForModal = ref() 
const setDataForModal = (row)=>{
  //row:実際の回答リスト
  let masterId = row.masterId
  testResultForModal.value = row
  //フィルターされたマスタデータのquestion.group
  let testData =  supporterStore.gotSurveyMasterByMasterId[0].question.group
  
  const groups = testData.map(function(group){
    let questions = group.questions.filter(
      (question) => {
        return question.index !== "display-none"
    })
    group.questions = questions
    return group
  })
  testData = groups
  surveyMasterDataForModal.value = testData 
}

const centerDialogVisible = ref(false)
const handleDetail = async(row)=>{
  setDataForModal(row)
  centerDialogVisible.value = true
}
</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テスト結果"/>
          <table class="table mu-auto" style="width: 50%;">
            <tbody>
              <tr><td class="fw-bold">テスト実施者</td><td>{{name}}</td></tr>
              <tr><td class="fw-bold">相談者区分</td><td>{{relationship}}</td></tr>
              <tr><td class="fw-bold">テストタイプ</td><td>{{testType}}</td></tr>
            </tbody>
          </table>
           <el-table row-key="no" :data="testResultList" style="width: 100%">
            <el-table-column type="index" label="No" column-key="no" ></el-table-column>
           <el-table-column  label="テスト実施日" sortable >
              <template #default="props">
                <p >{{$filters.displayDateFormat(props.row.submitted_date) }}</p>
              </template>
            </el-table-column>
           <el-table-column label="得点" sortable >
              <template #default="props">
                <p >{{props.row.score}}</p>
              </template>
           </el-table-column>
            <el-table-column fixed="right" label="結果詳細">
              <template #default="scope">
                <BasicButton @click="handleDetail(scope.row)" buttonName="回答を見る" />
              </template>
            </el-table-column>
          </el-table>

          <!-- modal -->
          <el-dialog v-model="centerDialogVisible" title="テスト結果詳細" width="90%" center class="modal">
            <div class=" d-flex mx-auto" style="width: 80%;">
              <table class="table me-auto mb-4" style="width: 45%;">
                <tbody>
                  <tr><td class="fw-bold">テストタイプ</td><td>{{testResultForModal.name}}</td></tr>
                  <tr><td class="fw-bold">実施日</td><td>{{$filters.displayDateFormat(testResultForModal.submitted_date)}}</td><td></td></tr>
                  <tr><td class="fw-bold">得点</td><td>{{testResultForModal.score}}点</td><td></td></tr>
                </tbody>
              </table>
            </div>
            <div class="overflow-y-wrap">
              <div class="overflow-y2">
                <div v-for="surveyData in surveyMasterDataForModal" class="mt-2 mb-4">
                  <div class="mb-2">{{surveyData.description}}</div>
                  <el-table row-key="no" :data="surveyData.questions" style="width: 100%">
                      <el-table-column prop="index" label="No" width="100"></el-table-column>
                      <el-table-column prop="label" label="設問"></el-table-column>
                      <el-table-column label="回答">
                        <template #default="props">
                          <div v-for="choice in props.row.choices">
                            <div v-if="choice.id == testResultForModal.answer[props.row.id].choice_id">
                              {{choice.label}}
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </el-dialog>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back" buttonName="戻る" />
          </div>
     </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style scoped>
.overflow-y-wrap{
  /* border: 1px solid #999; */
  height: 400px;
}
.overflow-y2{
  overflow-y: scroll;
  height: 100%;
}
</style>

