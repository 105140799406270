<script setup>
import {storeToRefs} from 'pinia'
import { useTenantStore } from '@/stores/tenantAdmin'
import { ref , onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
const router = useRouter()
const route = useRoute()
const tenantStore = useTenantStore()
let url = undefined
let videoList = ref()
const setData = async() =>{
  url = process.env.VUE_APP_BASEURL+"/manager/video"
  try{
    await tenantStore.getList(url,"videoList")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  videoList.value = tenantStore.gotVideoList
}
setData()

let type = undefined
let pageType = ""
const setPageType = (pageType) =>{
  let type = "pageType"
  tenantStore.handData(pageType,type)
}

const transitEditVideoContentPage = async(row)=>{
  pageType = "edit"
  await setPageType(pageType)
  url = process.env.VUE_APP_BASEURL+"/manager/video?id=" + row.id
  try{
    await tenantStore.getList(url,"videoData")
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
  await router.push({name:'T_VideoManagement'})
}

const transitNewVideoContentPage = async() =>{
  pageType = "create"
  await setPageType(pageType)
  await router.push({name:'T_VideoManagement'})
}

</script>
<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="ビデオライブラリ管理"/>
            <div class="top-button-area d-flex flex-row-reverse mb-4">
              <BasicButton @click="transitNewVideoContentPage" buttonName="ビデオコンテンツ新規追加" />
            </div>
            <el-table ref="tableRef" row-key="no" :data="videoList" style="width: 100%">
              <el-table-column type="index" label="No" sortable column-key="no" />
              <el-table-column prop="title" sortable label="動画名"></el-table-column>
              <el-table-column prop="url" label="URL" />
              <el-table-column fixed="right" label="編集" width="120">
                <template #default="scope">
                  <BasicButton @click="transitEditVideoContentPage(scope.row)" buttonName="編集" />
                </template>
              </el-table-column>
            </el-table>
            <div class="bottom-button-area d-flex">
              <BasicButton @click="$router.back" buttonName="戻る" />
            </div>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>
