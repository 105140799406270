<script setup>
import { reactive, ref } from 'vue'
import { FormInstance } from 'element-plus'
import { useTenantStore } from '@/stores/tenantAdmin'
import { useRoute, useRouter } from 'vue-router'
import NameForm from '@/components/modules/nameForm.vue'
import FuriganaForm from '@/components/modules/furiganaForm.vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import StatusFlg from '@/components/modules/statusFlg.vue'
import MailForm from '@/components/modules/mailForm.vue'
import OutcomeTypeForm from '@/components/modules/outcomeTypeForm.vue'
import PasswordForm from '@/components/modules/passwordForm.vue'
const formSize = ref('default')
const ruleFormRef = ref()
const props = defineProps({
  ruleForm:{
    id: Number,
    name: String,
    furigana: String,
    tel: Number,
    mailAddress: String,
    confirmMailAddress: String,
    password: String,
    outcomeType: Number,
    statusFlg: Boolean,
    staffId:String
  },
  staffList:Object,
  parentComponent:String
})
let staffOptions = []
const setStaffOptions = () =>{
  let staff = undefined
  for(const el of props.staffList) {
    staff = {value: el.id, label: el.name}
    staffOptions.push(staff)
  }
}
setStaffOptions()

const emit = defineEmits(['submit']) 
const submitForm = (formEl) => {
  console.log(formEl)
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      emit('submit')
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}

const isSelected = (rule, value, callback) => {
  console.log(props.ruleForm.staffId)
  if(!props.ruleForm.staffId){
    callback(new Error('スタッフを選択してください。'))
  }else{
    callback()
  }
}
const rules = reactive({
  staff:[
    { required: true, validator: isSelected, trigger: 'blur'}
    ]
})

</script>
<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="150px"
    class="demo-ruleForm"
    :size="formSize"
    :rules="rules"
  >
    <NameForm v-model="props.ruleForm.name" :ruleForm ="ruleForm" :model="ruleForm" />
    <FuriganaForm v-model="props.ruleForm.furigana" :ruleForm ="ruleForm" :model="ruleForm" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <MailForm 
      v-model:mailAddress="props.ruleForm.mailAddress"
      v-model:confirmMailAddress="props.ruleForm.confirmMailAddress" 
      :ruleForm ="ruleForm" :model="ruleForm" />
    <PasswordForm v-if="parentComponent == `create`" v-model="props.ruleForm.password" :ruleForm ="ruleForm" :model="ruleForm" />
    <OutcomeTypeForm v-model="props.ruleForm.outcomeType" :ruleForm ="ruleForm" :model="ruleForm" />
    <el-form-item label="担当スタッフ" prop="staff"  >
      <el-select v-model="props.ruleForm.staffId" placeholder=" " >
        <el-option
          v-for="item in staffOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <StatusFlg v-model="props.ruleForm.statusFlg" :ruleForm ="ruleForm" :model="ruleForm" statusType="statusFlg" label="相談者状態" />
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton @click="$router.back()" buttonName="戻る" />
        <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
      </el-form-item>
    </div>
</el-form>
</template>