<script setup>
import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {useChangePasswordStore} from '@/stores/changePassword'
import { useAuth } from '@/stores/auth'
const changePasswordStore = useChangePasswordStore()
const authStore = useAuth()
const router = useRouter()
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
    oldPass: "",
    newPass: ""
  })
const rules = reactive({
  oldPass: [
    { required: true, message: '必須項目です。', trigger: 'blur'}
  ],
  newPass: [
    { required: true, message: '必須項目です。', trigger: 'blur'},
  ]
})
let data = undefined
const setSubmitData = ()=>{
  data = {
    "old_password": ruleForm.oldPass,
    "new_password": ruleForm.newPass
  }
}

const submitPassword = async() =>{
  try{
    let url = process.env.VUE_APP_BASEURL+"/member/password"
    await changePasswordStore.putData(url,data)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'Login'})
        break
      case 422:
        break
      default:
        await router.push({name: 'InternalServerError'})
    }
  }
}

let auth = undefined
let pageName = ""
const setNextPage =() =>{
  //メンバーロール (1: user, 2: staff, 4: manager, 8: admin)
  if (authStore.gotRole == 8) {
    pageName = "S_Index"
  }else if(authStore.gotRole == 4){
    pageName = "T_Index"
  } else if(authStore.gotRole == 2){
    pageName = "ST_Index"
  }else if(authStore.gotRole == 1){
    pageName = "Index"
  }
}

const handleSubmit = (formEl)=>{
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      setSubmitData()
      submitPassword()
      // ロールで切り分ける
      setNextPage()
      router.push({name: pageName})
      return
    } else {
      return false
    }
  })

}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
        <el-container class="main-container">
          <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
          <el-main class="main">
            <ScreenName screenName="パスワード再設定"/>
              <el-form 
                :model="ruleForm"
                ref="ruleFormRef"
                :size="formSize"
              >
                <el-form-item label="以前のパスワード" prop="oldPass" :rules="rules.oldPass" >
                  <el-input v-model="ruleForm.oldPass" />
                </el-form-item>
                <el-form-item label="新しいパスワード" prop="newPass" :rules="rules.newPass" >
                  <el-input v-model="ruleForm.newPass" />
                </el-form-item>
                <div class="bottom-button-area d-flex">
                  <BasicButton @click="$router.back()" buttonName="戻る" />
                  <BasicButton @click="handleSubmit(ruleFormRef)" buttonName="登録" />
                </div>
              </el-form>
            </el-main>
        </el-container>
    </el-container>
  </div>
</template>

