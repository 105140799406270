<script setup>
import { reactive, ref } from 'vue'
import { useSupporterStore } from '@/stores/supporter'
import { useRoute, useRouter } from 'vue-router'
import NameForm from '@/components/modules/nameForm.vue'
import FuriganaForm from '@/components/modules/furiganaForm.vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import StatusFlg from '@/components/modules/statusFlg.vue'
import MailForm from '@/components/modules/mailForm.vue'
import OutcomeTypeForm from '@/components/modules/outcomeTypeForm.vue'
import PasswordForm from '@/components/modules/passwordForm.vue'
const formSize = ref('default')
const ruleFormRef = ref()
const props = defineProps({
  ruleForm:{
    name: String,
    furigana: String,
    tel: String,
    mailAddress: String,
    confirmMailAddress: String,
    outcomeType: String,
    statusFlg: Boolean,
    password:String
  },
  parentComponent: String
})

const emit = defineEmits(['submit']) 
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      emit('submit')
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}

</script>
<template>
  <el-form
    :model="ruleForm"
    label-width="150px"
    class="demo-ruleForm"
    :size="formSize"
    ref="ruleFormRef"
  >
    <NameForm v-model="props.ruleForm.name" :ruleForm ="ruleForm" :model="ruleForm" />
    <FuriganaForm v-model="props.ruleForm.furigana" :ruleForm ="ruleForm" :model="ruleForm" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <MailForm 
      v-model:mailAddress="props.ruleForm.mailAddress"
      v-model:confirmMailAddress="props.ruleForm.confirmMailAddress" 
      :ruleForm ="ruleForm" :model="ruleForm" />
    <PasswordForm v-if="parentComponent == `create`" v-model="props.ruleForm.password" :ruleForm ="ruleForm" :model="ruleForm" />
    <OutcomeTypeForm v-model="props.ruleForm.outcomeType" :ruleForm ="ruleForm" :model="ruleForm" />
    <StatusFlg v-model="props.ruleForm.statusFlg" :ruleForm ="ruleForm" :model="ruleForm" statusType="statusFlg" label="相談者状態" /> 
    <div class="bottom-button-area d-flex">
      <el-form-item>
        <BasicButton @click="$router.back()" buttonName="戻る" />
        <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
      </el-form-item>
    </div>
  </el-form>
</template>
