<script setup>
import { reactive, ref } from 'vue'
import { FormInstance } from 'element-plus'
import { useServiceStore } from '@/stores/service'
import { useRoute, useRouter } from 'vue-router'
const serviceStore = useServiceStore()
const router = useRouter()
const route = useRoute()
const description = ref()
const optionsDescription = ref()

let totalScore = ref()
let totalScoreId = undefined
let masterSurveyDataByMasterId = undefined
let surveyMasterDataForModal = ref() 
const filterDataForModal = async() =>{
  masterSurveyDataByMasterId = await serviceStore.gotMasterSurveyDataByMasterId
  let testData = masterSurveyDataByMasterId[0].question.group
  const groups = testData.map(function(group){
    group.questions.forEach(function(question){
      if(question.label == "total_score"){
        totalScoreId = question.id
      }
    })
    let questions = group.questions.filter(
      (question) => {
        return question.index !== "display-none"
        }
    )
    group.questions = questions
    return group
  })
  testData = groups
  surveyMasterDataForModal.value = testData

  serviceStore.gotTestAnswerList.answer.forEach(function(answer){
    if(answer.question_id == totalScoreId){
      totalScore.value = answer.score
    }
  })
}

filterDataForModal()
let testResultData = ref(serviceStore.gotTestAnswerList)
const back = ()=>{
  router.push({name:'Index'})
}
let updateKey = 0
const forceUpdate=()=> {
  updateKey += 1;
}
forceUpdate()
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="テスト結果"/>
          <div class=" d-flex mx-auto mb-3" style="width: 80%;">
            <table class="table me-auto mb-3" style="width: 45%;" :key="updateKey">
              <tbody>
                <tr><td class="fw-bold">テストタイプ</td><td>{{testResultData.name}}</td></tr>
                <tr><td class="fw-bold">実施日</td><td>{{ $filters.displayDateFormat(testResultData.submittedDate) }}</td></tr>
                <tr><td class="fw-bold">得点</td><td>{{totalScore}}点</td><td></td></tr>
              </tbody>
            </table>
          </div>
          <div v-for="surveyData in surveyMasterDataForModal" class="mt-2 mb-4">
            <div class="mb-2">{{surveyData.description}}</div>
            <el-table row-key="no" :data="surveyData.questions" style="width: 100%">
                <el-table-column prop="index" label="No" width="100"></el-table-column>
                <el-table-column prop="label" label="設問"></el-table-column>
                <el-table-column label="回答">
                  <template #default="props">
                    <div v-for="choice in props.row.choices">
                      <div v-if="choice.id == testResultData.answer[props.row.id].choice_id">
                        {{choice.label}}
                      </div>
                    </div>
                  </template>
                </el-table-column>
            </el-table>
          </div>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="back" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

