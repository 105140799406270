import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
export const useTenantStore = defineStore('tenantAdmin', {
  state: () => {
    return {
      staffList:[],
      staffId:undefined,
      editStaffData:undefined,
      userList:[],
      editUserData:undefined,
      outcomeId:undefined,
      pageType:undefined,
      videoList:undefined,
      videoData:undefined
    }
  },
  getters: {
    gotStaffList: (state)=>state.staffList, 
    gotStaffId: (state)=>state.staffId,
    gotEditStaffData: (state)=>state.editStaffData,
    gotUserList: (state)=>state.userList,
    gotEditUserData: (state)=>state.editUserData,
    gotUserId: (state)=>state.userId,
    gotPageType: (state)=>state.pageType,
    gotVideoList: (state)=>state.videoList,
    gotVideoData: (state)=>state.videoData
  },

  actions: {
    async getList(url,getType){
      const res = await axios.get(url)
      if (getType == "staffList"){
        this.staffList = res.data.result
      }else if(getType == "userList"){
        this.userList = res.data.result
      }else if(getType == "editStaffData"){
        this.editStaffData = res.data.result[0]
      }else if(getType == "editUserData"){
        this.editUserData = res.data.result[0]
      }else if(getType == "videoList"){
        this.videoList = res.data.result
      }else if(getType == "videoData"){
        this.videoData = res.data.result
      }
    },
    handId(id , type){ //staffとoutcome共通で使用
      if(type == "staff"){
        this.staffId = id
      }else if(type == "user"){
        this.userId = id
      }
    },
    handData(data,type){
      if(type == "pageType"){
        this.pageType = data
      }else if(type == "videoData"){
        this.videoData = data
      }
    },
    async postData(data , url){ //staffとoutcome共通で使用
      await axios.post(url,data)
    },
    async putData(data , url){
      const res = await axios.put(url,data)
    },
    filterEditUserDataById(){ 
      const userListTmp = this.userList
      const filteredData = userListTmp.filter(obj => obj.id == this.userId)
      this.editUserData = filteredData[0]
    },
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  }
})

