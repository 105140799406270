<script setup>
import { reactive, ref } from 'vue'
import {useRouter} from 'vue-router'
import { useResetPasswordStore } from '@/stores/resetPassword'
const resetPasswordStore = useResetPasswordStore()

const labelPosition = ref('top')
const inputForm = reactive({
  email:null
})
const errMessage = null
const errRef = reactive({
  errMessage
})
const router = useRouter()
const sendEmail = async(event)=>{
  var checkInput = false
  if (!inputForm.email) {
    errRef.errMessage="メールアドレスを入力してください"
    return
  }else {
    checkInput = true
    errRef.errMessage=""
    const data = {
      "mail" : inputForm.email
    }
    if(errRef.errMessage === ""){
      let url = process.env.VUE_APP_BASEURL+"/member/reset_password"
      try{
        await resetPasswordStore.postData(url,data)
      }catch(error){
        switch (error.response?.status) {
          default:
            await router.push({name: 'InternalServerError'})
        }
      }
    }
  }
}
</script>
<template>
 <body class="text-center" id="app">
    <router-view />
    <main class="form-signin">
      <p class="fw-bold fs-4 text-secondary">パスワード再設定用メール送信</p>
      <div class="messageArea">
        <p class="error">{{ errRef.errMessage }}</p>
      </div>
      <p class="text-start fw-light">ご登録いただいているメールアドレスを入力しの上、送信ボタンを押してください。<br>
        パスワード再設定用のURLを送信します。</p>
      <el-form
        :label-position="labelPosition"
        label-width="150px"
        style="max-width: 460px"
      >
        <el-form-item label="メールアドレス">
          <el-input v-model="inputForm.email"  placeholder="name@example.com"/>
        </el-form-item>
      </el-form>
      <div class="">
        <el-button color="#69C0E8" style="color: white;" @click="sendEmail">メール送信</el-button>
      </div>
    </main>
  </body>
</template>

<style scoped>
body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form-signin {
  width: 100%;
  max-width:400px;
  max-height:400px;
  padding: 15px;
  margin: 0 auto;
  background-color: #E8F5FB;
  border-radius: 4px;
}
#forgot-password{
  padding: 10px;
}
.error { color: red; }
.messageArea{
  height: 60px;
}
</style>