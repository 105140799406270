<script setup>
import { ref } from 'vue'
import { useServiceStore } from '@/stores/service'
const serviceStore = useServiceStore()
let url = ""
let getType = ""
let videoList = ref()
const setData = async() =>{
  url = process.env.VUE_APP_BASEURL+"/user/video"
  getType = "videoList"
  try{
    await serviceStore.getList(url,getType)
  }catch(error){
    switch (error.response?.status) {
      case 401:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: "Login"})
        break
      case 422:
	      break
      default:
        localStorage.clear()
        $cookies.remove('authToken')
        $cookies.remove('expiresIn')
        await router.push({name: 'InternalServerError'})
    }
  }
  videoList.value = serviceStore.gotVideoList
}
setData()

const centerDialogVisible = ref(false)
const dialogUrl = ref()
const dialogTitle = ref()
const dialogOpen = function(index) {
  dialogUrl.value = serviceStore.gotVideoList[index].url
  dialogTitle.value = serviceStore.gotVideoList[index].title
  centerDialogVisible.value = true
}

const handleClose = () =>{
  dialogUrl.value= ""
  centerDialogVisible.value = false
}
</script>

<template>
  <div class="common-layout">
    <el-container >
      <el-header class="header"><TheHeading/></el-header>
      <el-container class="main-container">
        <el-aside class="sidemenu" style="width:200px;"><TheSideBar/></el-aside>
        <el-main class="main">
          <ScreenName screenName="ビデオライブラリ"/>
          <el-space wrap class="b-container">
            <div v-for= "(item,index) in videoList" class="mb-4 me-4 d-flex flex-column" width="500px">
              <div><iframe :src="item.url" frameborder="0" allowfullscreen width="400px" height="225px"></iframe></div>
              <div class="d-flex flex-row justify-content-center" width="500px">
                <div class="videoTitle fs-6 me-3 mt-1 text-nowrap" width="60%">{{item.title}}</div>
                <BasicButton @click="dialogOpen(index)" buttonName="見る" />
              </div>
            </div>
          </el-space>
          <el-dialog v-model="centerDialogVisible" :title="dialogTitle" :before-close="handleClose" center width="1000px" height="800px"  class="b-container">
            <div class="d-flex justify-content-center">
              <iframe :src="dialogUrl" width="900px" height="550px" id="dialogContent"></iframe>
            </div>
          </el-dialog>
          <div class="bottom-button-area d-flex">
            <BasicButton @click="$router.back()" buttonName="戻る" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>