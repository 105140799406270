<template>
  <!--メールアドレスを確認してくださいページ-->
  <body class="" id="app">
    <main class="form-signin">
      <p class="fw-bold fs-4 text-secondary text-center">パスワードをお忘れの方</p>
      <p class="text-secondary text-area text-center">入力いただいたメールアドレスにメールをお送りしました。<br>
        メールが届かない場合は、再度手続きを行ってください。
      </p>
      <div class="bottom-button-area d-flex text-center">
        <BasicButton @click="$router.push('/')" buttonName="ログイン画面へ" />
      </div>
    </main>
  </body>
</template>
<style scoped>
body {
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form-signin {
  width: 100%;
  max-width:750px;
  height:230px;
  padding: 15px;
  margin: 0 auto;
  background-color: #E8F5FB;
  border-radius: 4px;
}
/* .text-area{
  margin-left: 60px;
} */
</style>