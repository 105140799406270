<script setup>
import {ref,computed,defineProps,reactive} from 'vue'
import { useStore } from '@/stores/serviceAdmin'
import {  useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const formSize = ref('default')
const ruleFormRef = ref()
import NameForm from '@/components/modules/nameForm.vue'
import FuriganaForm from '@/components/modules/furiganaForm.vue'
import PhoneForm from '@/components/modules/phoneForm.vue'
import StatusFlg from '@/components/modules/statusFlg.vue'
import MailForm from '@/components/modules/mailForm.vue'
import PasswordForm from '@/components/modules/passwordForm.vue'

const props = defineProps({
  ruleForm:{
    id: Number,
    name: String,
    furigana: String,
    tel: String,
    mailAddress: String,
    confirmMailAddress: String,
    // tenantOwnerStatus: Boolean,
    password: String,
    statusFlg: Boolean
  },
  parentComponent:Boolean
})

//メソッド内で親のメソッドを実行する場合、記載が必要
const emit = defineEmits(['submit']) 
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      emit('submit')
      return
    } else {
      console.log('error submit!')
      return false
    }
  })
}
</script>

<template>
  <el-form
    :model="ruleForm"
    label-width="150px"
    class="demo-ruleForm"
    :size="formSize"
    ref="ruleFormRef"
  >
    <NameForm v-model="props.ruleForm.name" :ruleForm ="ruleForm" :model="ruleForm" />
    <FuriganaForm v-model="props.ruleForm.furigana" :ruleForm ="ruleForm" :model="ruleForm" />
    <PhoneForm v-model="props.ruleForm.tel" :ruleForm ="ruleForm" :model="ruleForm" />
    <MailForm 
      v-model:mailAddress="props.ruleForm.mailAddress"
      v-model:confirmMailAddress="props.ruleForm.confirmMailAddress" 
      :ruleForm ="ruleForm" :model="ruleForm" />
    <PasswordForm v-if="parentComponent == `create`" v-model="props.ruleForm.password" :ruleForm ="ruleForm" :model="ruleForm" />
    <!-- <StatusFlg v-model="props.ruleForm.tenantOwnerStatus" :ruleForm ="ruleForm" :model="ruleForm" statusType="tenantOwnerStatus" label="テナント管理者状態" /> -->
    <StatusFlg v-model="props.ruleForm.statusFlg" :ruleForm ="ruleForm" :model="ruleForm" statusType="statusFlg" label="スタッフ状態" />
    <div class="bottom-button-area d-flex">
      <BasicButton @click="$router.back()" buttonName="戻る" />
      <BasicButton @click="submitForm(ruleFormRef)" buttonName="登録" />
    </div>
  </el-form>
</template>
